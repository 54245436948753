import { Card, CardBody, CardHeader } from "reactstrap";
import { Fragment, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import JSZip from "jszip";
import { AesEncrypt } from "../../../Service/crypto";
import webservice from "../../../Service/webservice";
import jsPDF from "jspdf";

const Patientimage = () => {
    const { id } = useParams();
    const [imageUrls, setImageUrls] = useState<{ url: string; step: number }[]>([]);
    const [loading, setLoading] = useState<boolean>(true);
    const [pdfUrl, setPdfUrl] = useState<string | null>(null); // State to store the PDF URL
    const navigate = useNavigate();
    const patientName = localStorage.getItem("patient_name");

    useEffect(() => {
        const fetchData = async (id: string) => {
            try {
                setLoading(true);
                const EncParams = AesEncrypt(id);
                const response = await fetch(webservice + "api/screenshot-Paitentsimage", {
                    method: "POST",
                    body: JSON.stringify({ EncParams }),
                    headers: { "Content-Type": "application/json; charset=UTF-8" },
                });
                if (!response.ok) {
                    throw new Error(`HTTP error! Status: ${response.status}`);
                }
                const blob = await response.blob();
                const zip = await JSZip.loadAsync(blob);
                const urls = await Promise.all(
                    Object.keys(zip.files)
                        .filter(relativePath => !zip.files[relativePath].dir)
                        .filter(relativePath => !relativePath.endsWith("screenshots.zip"))
                        .map(async (relativePath) => {
                            const match = relativePath.match(/step_(\d+)_/);
                            const step = match ? parseInt(match[1], 10) : 0;
                            const zipEntry = zip.files[relativePath];
                            const fileBlob = await zipEntry.async("blob");
                            return { url: URL.createObjectURL(fileBlob), step };
                        })
                );
                urls.sort((a, b) => a.step - b.step);
                setImageUrls(urls);
                setLoading(false);
            } catch (error) {
                console.error("Error fetching and extracting ZIP:", error);
                setLoading(false);
            }
        };

        if (id) {
            fetchData(id);
        }
    }, [id]);

    useEffect(() => {
        // Automatically trigger openPDFInBrowser when imageUrls are loaded
        if (!loading && imageUrls.length > 0) {
            openPDFInBrowser();
        }
    }, [loading, imageUrls]);

    const openPDFInBrowser = () => {
        const pdf = new jsPDF();
        let imagesLoaded = 0;

        imageUrls.forEach(({ url }, index) => {
            const img = new Image();
            img.src = url;
            img.onload = () => {
                const imgWidth = 180;
                const imgHeight = (img.height * imgWidth) / img.width;
                if (index !== 0) pdf.addPage();

                // Add patient name to each page (you can adjust the position and style as needed)
                pdf.setFont("helvetica", "normal");
                pdf.setFontSize(12);
                pdf.text(`Patient: ${patientName}`, 15, 15); // Add patient name to the top of each page

                pdf.addImage(img, 'JPEG', 15, 20, imgWidth, imgHeight);

                imagesLoaded++;
                if (imagesLoaded === imageUrls.length) {
                    const pdfBlob = pdf.output("blob");
                    const pdfUrl = URL.createObjectURL(pdfBlob);
                    setPdfUrl(pdfUrl); // Set the PDF URL to state
                }
            };
        });
    };



    return (
        <Fragment>
            <Card className="invoice-card" style={{ height: "95%" }}>
                <CardHeader>
                    <div className="header-top" style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
                        <i
                            className="fa fa-arrow-circle-left"
                            id="back"
                            style={{ fontSize: '40px', cursor: 'pointer' }}
                            onClick={() => navigate(-1)}
                        ></i>
                        <h2>Portal Screenshot</h2>
                    </div>
                </CardHeader>

                {loading ? (
                    <div style={{
                        flex: 1,
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        padding: "10px",
                    }}>
                        <div className="fileloader"></div> {/* Common Loading Indicator */}
                    </div>
                ) : imageUrls.length === 0 ? (
                    <CardBody style={{ textAlign: "center", padding: "20px" }}>
                        <h4>No data found</h4>
                    </CardBody>
                ) : (
                    <CardBody style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                        {pdfUrl && (
                            <div style={{ width: "100%", height: "700px", border: "1px solid #ccc" }}>
                                {/* Embed the PDF using an iframe */}
                                <iframe
                                    src={pdfUrl}
                                    title="Generated PDF"
                                    width="100%"
                                    height="100%"
                                    style={{ border: "none" }}
                                />
                            </div>
                        )}
                    </CardBody>
                )}
            </Card>
        </Fragment>
    );
};

export default Patientimage;
