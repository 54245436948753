import { Card, Col, Container, Modal, Row } from "reactstrap";
import Breadcrumbs from "../../../CommonElements/Breadcrumbs/Breadcrumbs";
import { Emails, Tickets } from "../../../utils/Constant";
import { TabContent, TabPane } from "reactstrap";
import { H3, P } from "../../../AbstractElements";
import { useAppDispatch } from "../../../ReaduxToolkit/Hooks";
import { useEffect, useMemo, useState, useCallback } from "react";
import { fetchTicketDatas } from "./store";
import webservice from "../../../Service/webservice";
import { Tooltip } from "react-tooltip";
import "react-tooltip/dist/react-tooltip.css";
import { AesEncrypt } from "../../../Service/crypto";

const EmailApp = () => {
  const dispatch = useAppDispatch();
  const [emailList, setEmailList] = useState<any[]>([]);
  const [selectedTicket, setSelectedTicket] = useState<any>(null);
  const [patientData, setPatientData] = useState<any>(null);
  const [patientId, setPatientId] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(true);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedImage, setSelectedImage] = useState<string | null>(null);


  // Memoized user data
  const userData = useMemo(
    () => ({
      UserType: localStorage.getItem("UserType"),
      id: localStorage.getItem("id"),
      Role: localStorage.getItem("Role"),
    }),
    []
  );

  // Fetch ticket data
  const getTicketsData = useCallback(async () => {
    try {
      const response = await dispatch(fetchTicketDatas(userData));
      if (response.payload === "No data found") {
        setEmailList([]);
      } else {
        setEmailList(response.payload);
      }
    } catch (error) {
      console.error("Error fetching ticket data:", error);
    }
  }, [dispatch, userData]);

  useEffect(() => {
    getTicketsData();
  }, [getTicketsData]);

  // Fetch patient data when patientId changes
  const fetchPatientData = useCallback(async () => {
    if (!patientId) return;
    setLoading(true); // Show loading before fetching data
    try {
      const EncParams = AesEncrypt(patientId);
      const response = await fetch(webservice + "api/Email-fetchErrorDatas", {
        method: "POST",
        body: JSON.stringify({ EncParams }),
        headers: { "Content-Type": "application/json; charset=UTF-8" },
      }).then((res) => res.json());

      setPatientData(response[0]);
    } catch (error) {
      console.error("Error fetching patient data:", error);
    } finally {
      setLoading(false); // Hide loading after fetching data
    }
  }, [patientId]);

  useEffect(() => {
    fetchPatientData();
  }, [fetchPatientData]);

  // Handle ticket selection
  const handleTicketClick = (ticket: any) => {
    setSelectedTicket(ticket);
    setPatientId(ticket.id);
  };

  // Get greeting message
  const getGreeting = (date: Date) => {
    const hour = date.getHours();
    if (hour >= 5 && hour < 12) return "Good Morning,";
    if (hour >= 12 && hour < 18) return "Good Afternoon,";
    return "Good Evening,";
  };

  // Open image modal
  const openImage = (base64: string) => {
    setSelectedImage(base64);
    setIsModalOpen(true);
  };

  return (
    <>
      <Breadcrumbs mainTitle={Emails} parent={Emails} title={Emails} />
      <Container fluid>
        <div className="email-wrap emailapp-items">
          <Row>
            {/* Left Panel - Ticket List */}
            <Col xl="4" md="12">
              <Card className="email-body" style={{ height: "700px", overflow: "hidden" }}>
                <div className="email-top d-flex align-items-center justify-content-between">
                  <H3 className="m-0">{Tickets}</H3>
                  <span>({emailList.length})</span>
                </div>

                <div className="inbox custom-scrollbar" style={{ height: "calc(100% - 50px)", overflowY: "auto" }}>
                  {emailList.length > 0 ? (
                    emailList.map((ticket: any, index: number) => {
                      const errorDate = new Date(ticket.Error_date);
                      return (
                        <div
                          key={index}
                          className="d-flex justify-content-between align-items-center ticket-item"
                          onClick={() => handleTicketClick(ticket)}
                          style={{ cursor: "pointer", padding: "10px", borderBottom: "1px solid #ddd" }}
                        >
                          <div className="flex-grow-1">
                            <H3>
                              <span data-tooltip-id="patient-tooltip" data-tooltip-content="Patient Name">
                                {ticket.Patient_Name}
                              </span>
                            </H3>
                            <P>
                              <span data-tooltip-id="client-tooltip" data-tooltip-content="Client Name">
                                {ticket.client_name}
                              </span>
                            </P>
                          </div>
                          <small>
                            <span className="digits email-badge">({errorDate.getDate()}</span>{" "}
                            {errorDate.toLocaleString("default", { month: "long" })}{" "}
                            <span className="digits email-badge">{errorDate.getFullYear()})</span>
                          </small>
                        </div>
                      );
                    })
                  ) : (
                    <div className="d-flex justify-content-center align-items-center" style={{ height: "100%" }}>
                      <P>No data available</P>
                    </div>
                  )}
                  <Tooltip id="patient-tooltip" />
                  <Tooltip id="client-tooltip" />
                </div>
              </Card>
            </Col>

            {/* Right Panel - Ticket Details */}
            {selectedTicket && (
              <Col xl="8" md="12">
                <Card className="email-body">
                  <TabContent>
                    <TabPane className="fade active show">
                      <div className="email-content">
                        <div className="email-top">
                          <H3>
                            {selectedTicket.Patient_Name}{" "}
                            <small>
                              <span>({new Date(selectedTicket.Error_date).getDate()}</span>{" "}
                              {new Date(selectedTicket.Error_date).toLocaleString("default", { month: "long" })}{" "}
                              <span>{new Date(selectedTicket.Error_date).getFullYear()})</span>
                            </small>
                          </H3>
                        </div>
                        {loading ? (
                          <p style={{ textAlign: "center", marginTop: "15px", color: 'black' }}>Loading data...</p> // Show loading message
                        ) : patientData ? (
                          <div className="email-wrapper">
                            <>
                              <P>{getGreeting(new Date(patientData.Error_date))}</P>
                              <P>{patientData.Payer_name}</P>
                              <H3>Error: {patientData.error_message}</H3>
                              <P>
                                Location:{" "}
                                <a href={patientData.error_page_url} target="_blank" rel="noopener noreferrer">
                                  {patientData.error_page_url}
                                </a>
                              </P>

                              {patientData.image_base64 ? (
                                <div style={{ textAlign: "center", marginTop: "15px" }}>
                                  <img
                                    src={`data:image/jpeg;base64,${patientData.image_base64}`}
                                    alt="Error Screenshot"
                                    style={{ width: "100%", maxWidth: "500px", borderRadius: "8px", cursor: "pointer" }}
                                    onClick={() => openImage(patientData.image_base64)}
                                  />
                                </div>
                              ) : (
                                <P>No image available</P>
                              )}
                            </>
                            {/* Image Modal */}
                            <Modal isOpen={isModalOpen} toggle={() => setIsModalOpen(false)} size="xl" centered>
                              <img src={`data:image/jpeg;base64,${selectedImage}`} alt="Full Screen View" style={{ width: "100%" }} />
                            </Modal>
                          </div>
                        ) : (
                          <P>Please select a ticket to view details.</P>
                        )}
                      </div>
                    </TabPane>
                  </TabContent>
                </Card>
              </Col>
            )}

          </Row>
        </div>
      </Container>
    </>
  );
};

export default EmailApp;
