// ** Custom Components
import Breadcrumbs from "../../../CommonElements/Breadcrumbs/Breadcrumbs";

import React, { Fragment, useEffect, useState } from "react";

import { Card, CardBody, Nav, NavItem, NavLink, TabContent, TabPane } from "reactstrap";
import classnames from "classnames";
import {  useLocation } from 'react-router-dom';


import BulkUpload from "./Bulkupload";
import ManualEntries from "./ManualEntries";

const ClientIndex: React.FC = () => {
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const view = queryParams.get('view');    

    // Define the initial state outside the component
    let initialActiveTab: string;
    if (localStorage.UserType === "MOS") {
        initialActiveTab = '1';
    } else {
        initialActiveTab = '2';
    }

    // Inside your component
    const [activeTab, setActiveTab] = useState<string>('1');

    useEffect(() => {
    if(view === null){
        setActiveTab("1")
    }else if(view ==="manual"){
        setActiveTab("2")
    }
    },[view,setActiveTab])

    // Explicitly define the type for the toggle function
    const toggletab: (tab: string) => void = (tab) => {
        if (activeTab !== tab) setActiveTab(tab);
    };

    return (
        <Fragment>
            <TabContent activeTab={activeTab}>
                <TabPane tabId="1"> <Breadcrumbs mainTitle="Bulk Upload" title="Bulk Upload" /></TabPane>
                <TabPane tabId="2"><Breadcrumbs mainTitle="Manual Entries" title="Manual Entries" /></TabPane>
            </TabContent>
            <Card>
                <CardBody>
                    <div className="my-nav">
                        <Nav tabs className="custom-nav-tabs">
                                <NavItem className="tabcont">
                                    <NavLink
                                        className={classnames("nav-link-custom", { active: activeTab === '1' })}
                                        onClick={() => { toggletab('1'); }}
                                    >
                                        Bulk Upload
                                    </NavLink>
                                </NavItem>
                            <NavItem className="tabcont">
                                
                                    <NavLink
                                        className={classnames("nav-link-custom", { active: activeTab === '2' })}
                                        onClick={() => { toggletab('2'); }}
                                    >
                                    Manual Entries
                                    </NavLink>
                            </NavItem>
                        </Nav>
                    </div>
                    <CardBody>
                        <TabContent activeTab={activeTab}>
                            <TabPane tabId="1">
                                <BulkUpload />
                            </TabPane>
                            <TabPane tabId="2">
                                <ManualEntries />
                            </TabPane>
                        </TabContent>
                    </CardBody>
                </CardBody>
            </Card>
        </Fragment>
    );
};

export default ClientIndex;
