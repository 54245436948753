import ShoppingPlace from "../Pages/DashBoard/ShoppingPlace/ShoppingPlace";
import CRMDashboard from "../Pages/DashBoard/CRMDashboard/CRMDashboard";

import UserProfile from "../Pages/Apps/User/UserProfile/UserProfile";
import UserEdit from "../Pages/Apps/User/UserEdit/UserEdit";
import UserCard from "../Pages/Apps/User/UserCard/UserCard";

import Eligibility from "../Pages/Apps/Eligibility/Eligibility";
import Appointments from "../Pages/Apps/Appointments/Appointments";
import AppMenu from "../Pages/Apps/AppMenu/AppMenu";
import AddMenu from "../Pages/Apps/AppMenu/AddMenu";
import EditMenu from "../Pages/Apps/AppMenu/EditMenu";
import UserType from "../Pages/Apps/UserType/usertype";

import ClientManagement from "../Pages/Apps/ClientManagement/ClientManagement";
import AddClient from "../Pages/Apps/ClientManagement/AddClient";
import EditClient from "../Pages/Apps/ClientManagement/EditClient";
import UserManagement from "../Pages/Apps/UserManagement/UserManagement";
import AddUser from "../Pages/Apps/UserManagement/AddUser";
import EditUser from "../Pages/Apps/UserManagement/EditUser";

import Vendor from "../Pages/Apps/Vendor/Vendor";
import Vendoradmin from "../Pages/Apps/Vendor/VendorAdmin";
import AddVendor from '../Pages/Apps/Vendor/Addvendor';
import Addvendoradmin from "../Pages/Apps/Vendor/AddvendorAdmin";
import EditVendor from "../Pages/Apps/Vendor/Editvendor";
import AddTeam from "../Pages/Apps/Vendor/AddTeam";
import EditTeam from "../Pages/Apps/Vendor/EditTeam";
import Addpayor from "../Pages/Apps/Payor/Addpayor";
import Editpayor from "../Pages/Apps/Payor/Editpayor";
import Payor from "../Pages/Apps/Payor/Payor";
import Addonder from "../Pages/Apps/Onderful_api/Addapi";
import Onder from "../Pages/Apps/Onderful_api/Api";
import Editonder from "../Pages/Apps/Onderful_api/Editapi";
import Patients from "../Pages/Apps/Patients/Patients";
import PatientEligibility from "../Pages/Apps/Patients/PatientEligibility";
import EditPatientData from "../Pages/Apps/Patients/EditPatientData";
import LogoutPage from "../Pages/Authentication/Logout";
import Loginhistory from "../Pages/Apps/Userloginhistory/History";
import ReportAudit from "../Pages/Apps/Auditlog/Reportaudit";
import BulkUpload from '../Pages/Apps/BulkUpload/index';
import PatientListing from '../Pages/Apps/BulkUpload/Patient';
import ClientMapping from '../Pages/Apps/ClientMapping/clientmapping';

import EditVendorAdmin from "../Pages/Apps/Vendor/EditVendorAdmin";
import ClientIndex from "../Pages/Apps/ClientManagement";
import Addclientadmin from "../Pages/Apps/ClientManagement/Addclientadmin";
import Clientadmin from "../Pages/Apps/ClientManagement/ClientAdmin";
import EditClientadmin from "../Pages/Apps/ClientManagement/Editclientadmin";
import VendorIndex from "../Pages/Apps/Vendor";

import Userlog from "../Pages/Apps/Activeruserlist";
import TemplateCreation from "../Pages/Apps/TemplateCreation/TemplateCreation";
import NewTemplateCreation from "../Pages/Apps/TemplateCreation/NewTemplateCreation";
import FieldMaster from "../Pages/Apps/TemplateCreation/FieldMaster";
// import EditTemplate from "../Pages/Apps/TemplateCreation/EditTemplate";

import ExportAppointment from "../Pages/Apps/Patients/Exportappointment";
import AddAppointment from "../Pages/Apps/Patients/Addappointment";
import Chating from "../Pages/Apps/Chat/Chating/Chating";
import Email from "../Pages/Apps/Email/Email";

import Addinsurance from "../Pages/Apps/Insurance/Addinsurancre";
import Insurance from "../Pages/Apps/Insurance/insurance";
import Editinsurance from "../Pages/Apps/Insurance/Editinsurance";
// import ScreenshotMaster from "../Pages/Apps/Patientscreenshot/Screenshotmaster";
import Patientimage from "../Pages/Apps/Patientscreenshot/Patientimage";
// E: \ivp\staging\app\src\Pages\Apps\Patientscreenshot\Screenshotmaster.tsx

const routes = [
  // dashboard routes
  { path: `dashboard`, Component: <ShoppingPlace /> },
  { path: `eligibility`, Component: <Eligibility /> },
  { path: `appointments`, Component: <Appointments /> },
  { path: `appmenu`, Component: <AppMenu /> },
  { path: `appmenu/addmenu`, Component: <AddMenu /> },
  { path: `appmenu/editmenu`, Component: <EditMenu /> },
  { path: `usertype`, Component: <UserType /> },
  { path: `dashboard/crmdashboard`, Component: <CRMDashboard /> },
 { path: `BulkUpload`, Component: <BulkUpload /> },
 { path: `bulkupload/patient`, Component: <PatientListing /> },
 { path: `bulkupload/patient/:id`, Component: <PatientListing /> },
  { path: `ClientMapping`, Component: <ClientMapping /> },
  { path: `ClientMapping/:id`, Component: <ClientMapping /> },


  { path: `userManagement`, Component: <UserManagement /> },
  { path: `userManagement/addUser`, Component: <AddUser /> },
  { path: `userManagement/editUser`, Component: <EditUser /> },

  //Client Management
  { path: `client`, Component: <ClientIndex /> },
  { path: `clientManagement`, Component: <ClientManagement /> },
  { path: `clientManagement/addClient`, Component: <AddClient /> },
  { path: `clientManagement/addClient/:id`, Component: <AddClient /> },
  { path: `clientManagement/editClient`, Component: <EditClient /> },
  { path: `clientManagement/addClientadmin`, Component: <Addclientadmin /> },
  { path: `clientManagement/editClientadmin`, Component: <EditClientadmin /> },
  { path: `clientadmin`, Component: <Clientadmin /> },


  { path: `templateCreation`, Component: <TemplateCreation /> },
  { path: `templateCreation/newTemplateCreation`, Component: <NewTemplateCreation/> },
  { path: `templateCreation/FieldMaster`, Component: <FieldMaster/> },
  // { path: `templateCreation/EditTemplate/:id`, Component: <EditTemplate/> },
  // { path: `templateCreation/EditTemplate`, Component: <EditTemplate/> },

  //  User
  { path: `users/usersprofile`, Component: <UserProfile /> },
  { path: `users/changepassword`, Component: <UserEdit /> },
  { path: `users/userscards`, Component: <UserCard /> },


    //Vendor
    { path: `vendormanagement`, Component: <VendorIndex /> },
    { path: `vendor`, Component: <Vendor /> },
    { path: `vendoradmin`, Component: <Vendoradmin /> },
    { path: `addvendoradmin`, Component: <Addvendoradmin /> },
    { path: `addvendor`, Component: <AddVendor /> },
    { path: `addvendor/:id`, Component: <AddVendor /> },
    { path: `editvendor`, Component: <EditVendor /> },
    { path: `addTeam`, Component: <AddTeam /> },
    { path: `addTeam/:id`, Component: <AddTeam /> },
    { path: 'addvendor/:id/:teamId', Component: <AddVendor /> },
    { path: `editTeam`, Component: <EditTeam /> },
    { path: `editvendoradmin`, Component: <EditVendorAdmin /> },

  
    // Payor
    { path: `payor`, Component: <Payor /> },
    { path: `addpayor`, Component: <Addpayor /> },
    { path: `editpayor`, Component: <Editpayor /> },

    // Onderfulapi
    { path: `addonder`, Component: <Addonder /> },
    { path: `onder`, Component: <Onder /> },
    { path: `editonder`, Component: <Editonder /> },

    // Logout
    { path: `logout`, Component: <LogoutPage /> },
    { path: `loginhistory`, Component: <Loginhistory /> },
    { path: `audithistory`, Component: <ReportAudit /> },
    { path: `activeuser`, Component: <Userlog /> },


  { path: `patients`, Component: <Patients /> },
  { path: `addappointment`, Component: <AddAppointment /> },
  { path: `exportappointment`, Component: <ExportAppointment /> },
  { path: `patient-eligibility`, Component: <PatientEligibility /> },
  { path: `patient-eligibility/:id`, Component: <PatientEligibility /> },
  { path: `edit-patient`, Component: <EditPatientData /> },
  { path: `edit-patient/:id`, Component: <EditPatientData /> },

  { path: `chatapp`, Component:<Chating />},
  { path: `mail`, Component:<Email />},


  { path: `addinsurance`, Component:<Addinsurance />},
  { path: `insurance`, Component:<Insurance />},
  { path: `editinsurance`, Component:<Editinsurance />},
  // { path: `ScreenshotMaster`, Component: <ScreenshotMaster />},
  { path: `Patientimage/:id`, Component: <Patientimage/>},

];
export default routes;