import React, { useEffect, useState } from "react";
import { Card, CardBody, CardHeader, Col, Label, Row } from "reactstrap";
import ReactApexChart from "react-apexcharts";
import { H2, H3 } from "../../../../AbstractElements";
import webservice from "../../../../Service/webservice";
import websocket_webservice from "../../../../Service/websocket_webservice";
import { AesDecrypt, AesEncrypt } from "../../../../Service/crypto";
import { ApexOptions } from 'apexcharts';
import { Controller, useForm } from "react-hook-form";
import Select, { SingleValue } from "react-select";
import classnames from "classnames";
import { getfilestatus } from '../store/index';
import { useDispatch } from "react-redux";
import Chart from "react-google-charts";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { format } from "date-fns";


interface CompanyOption {
    label: string;
    value: string;
}

interface IClientOption {
    label: string;
    value: string;
}

interface File {
    uploaded_date?: string;
    ocr_completed_date?: string;
    Completed_date?: string;
    approved_date?: string;
    review_date?: string;
    Error_date?: string;
    Data_Fetched?: string;
    data_extraction?: string;
    client_id?: string;
    client_name?: string;
    id?: string;
    ocr_status?: string;
    xml_conversion?: string;
    uploaded_status?: string;
    RegenrateId?: string;
    download_status?: string;
}

const OrderStatus = () => {
    const { control } = useForm();
    const dispatch = useDispatch();
    const [selectedClient, setSelectedClient] = useState<string>('All');
    const [selectedClientId, setSelectedClientId] = useState<string>('ALL');
    const [clientOptions, setClientOptions] = useState<IClientOption[]>([]);
    const [completedFilesCount, setCompletedFilesCount] = useState<number[]>([]);
    const [totalFilesCount, setTotalFilesCount] = useState<number[]>([]);
    const [Yet_to_startCount, setTotalYet_to_startCount] = useState<number[]>([]);
    const [regenrateFilesCount, setRegenratedFilesCount] = useState<number>(0);
    const [downloadFilesCount, setDownloadFilesCount] = useState<number>(0);
    const [inprogressFilesCount, setinprogressdata] = useState<number[]>([]);
    const [errorFilesCount, seterrorCount] = useState<number[]>([]);
    const [selectedDate, setSelectedDate] = useState<Date | null>(new Date());

    

    // Calculate percentages dynamically
    const FilesCount = completedFilesCount.length + inprogressFilesCount.length + errorFilesCount.length + Yet_to_startCount.length + regenrateFilesCount + downloadFilesCount;
    const calculatePercentage = (count: number) => FilesCount > 0 ? Math.round((count / FilesCount) * 100) : 0;

    const UserType = localStorage.UserType;
    const Id = localStorage.VendorId;

    const get_companyname = async () => {
        let bodyData = {};
        if (UserType === 'MOS') {
            bodyData = { UserType };
        } else if (UserType === 'Vendor') {
            bodyData = { UserType, VendorId: Id };
        }

        const response = await fetch(webservice + 'api/Dashboard/getclient', {
            method: 'POST',
            headers: {
                'Content-type': 'application/json; charset=UTF-8',
            },
            body: JSON.stringify(bodyData),
        }).then((response) => response.json());

        const TempData = AesDecrypt(response);
        return TempData;
    };

    useEffect(() => {
        const fetchRoleId = async () => {
            try {
                const response = await get_companyname();
                const companyData = response.map((data: any) => ({
                    label: data.CompanyName,
                    value: data.id,
                }));

                const sortedCompanyData = companyData.slice().sort((a: CompanyOption, b: CompanyOption) => a.label.localeCompare(b.label));
                const allOption = { label: 'All', value: 'ALL' };
                const companyDataWithAll = [allOption, ...sortedCompanyData];

                setClientOptions(companyDataWithAll);
            } catch (error) {
                console.error(error);
            }
        };

        fetchRoleId();
        const socket = new WebSocket(`${websocket_webservice}ws/ClientDropdown/`);

        socket.onmessage = () => {
            fetchRoleId();
        };

        return () => {
            if (socket.readyState === 1) {
                socket.close();
            }
        }
    }, []);

    const handleSelectClientChange = (selectedOption: SingleValue<IClientOption>) => {
        if (selectedOption) {
            setSelectedClient(selectedOption.label);
            setSelectedClientId(selectedOption.value);
        } else {
            setSelectedClient('');
            setSelectedClientId('ALL');
        }
    }

    const ViewAuditLog = async () => {
        const Username = localStorage.UserName;
        const Userid = localStorage.id;
        const Clientname = selectedClient;
        const Chartname = 'File Status'
        const Data = { Username, Clientname, Userid, Chartname };

        const EncParams = AesEncrypt(JSON.stringify(Data));
        const response = await fetch(webservice + 'api/Dashboard/auditlog', {
            method: 'POST',
            body: JSON.stringify({ EncParams }),
            headers: {
                'Content-type': 'application/json; charset=UTF-8',
            }
        }).then((response) => response.json());
        return response;
    };

    useEffect(() => {
        let clientid: string | null;

        if (localStorage.UserType === 'MOS') {
            clientid = selectedClientId;
        } else if (localStorage.UserType === 'Vendor') {
            clientid = selectedClientId;
        } else {
            clientid = localStorage.ClientId;
        }

        const formattedDate = selectedDate ? format(selectedDate, "yyyy-MM-dd") : null; // Format date
        const data = { clientid,  formattedDate }; 

        const fetchData = async () => {
            try {
                // Pass both clientid and formattedDate
                const resReceived = await dispatch<any>(getfilestatus(data )); 
                const dataArrayReceived: File[] = AesDecrypt(resReceived.payload);

                const regenratedCount = dataArrayReceived.filter((file) =>
                    file.RegenrateId && file.RegenrateId.length > 0
                ).length;
                setRegenratedFilesCount(regenratedCount);

                const DownloadCount = dataArrayReceived.filter((file) =>
                    file.download_status && file.download_status.length > 0
                ).length;

                setDownloadFilesCount(DownloadCount);

                const filterDataByStatus = (
                    dataArray: File[],
                    status: string
                ): number[] => {
                    const filteredData = dataArray.filter((file) => {
                        return (
                            (status === "completed"
                                ? file.Data_Fetched === "Completed" 
                                : status === 'Yet to Start'
                                    ? file.Data_Fetched === 'Yet to Start'
                                : status === "In progress"
                                    ? file.Data_Fetched === "In progress"
                                    : status === "Error"
                                        ? file.Data_Fetched === "Error"
                                        : true)
                        );
                    });

                    const counts = filteredData.map(() => 1);

                    if (status === "Yet to Start") {
                        setTotalYet_to_startCount(counts);
                    } else if (status === "In progress") {
                        setinprogressdata(counts);
                    } else if (status === "Error") {
                        seterrorCount(counts);
                    } else if (status === "completed") {
                        setCompletedFilesCount(counts);
                    } else if (status === "received") {
                        setTotalFilesCount(counts);
                    }

                    return counts;
                };

                filterDataByStatus(dataArrayReceived, "received");
                filterDataByStatus(dataArrayReceived, "In progress");
                filterDataByStatus(dataArrayReceived, "completed");
                filterDataByStatus(dataArrayReceived, "Error");
                filterDataByStatus(dataArrayReceived, "Yet to Start");

            } catch (error) {
                console.error("Error fetching data:", error);
            }
        };

        fetchData();
        const socket = new WebSocket(`${websocket_webservice}ws/Dashboardpatient/`);

        socket.onmessage = () => {
            fetchData();
        };

        return () => {
            if (socket.readyState === 1) {
                socket.close();
            }
        }
    }, [dispatch, selectedClientId, selectedClient, selectedDate]);


    useEffect(() => {
        ViewAuditLog();
    }, []);

    const columnColors = {
        yet_to_start:'#ff8430',
        inprogress: '#ffff00',
        completed: '#009933',
        download: '#9966ff',
        apierror: '#ff0000',
        regenerated: '#990099',
    };

    const allCountsZero =
        inprogressFilesCount.length === 0 &&
        completedFilesCount.length === 0 &&
        Yet_to_startCount.length === 0 &&
        downloadFilesCount === 0 &&
        errorFilesCount.length === 0 &&
        regenrateFilesCount === 0;

    const googleChartDatas = {
        GooglePieChart1: {
            width: "100%",
            height: "400px",
            chartType: "PieChart",
            data: allCountsZero
                ? [
                    ["Task", "Count"],
                    ["No Data", 1],
                ]
                : [
                    ["Task", "Count"],
                    ["Yet to Start", Yet_to_startCount.length],
                    ["In Progress", inprogressFilesCount.length],
                    ["Completed", completedFilesCount.length],
                    ["Downloaded", downloadFilesCount],
                    ["API Error", errorFilesCount.length],
                    ["Regenerated", regenrateFilesCount],
                ],
            option: {
                colors: allCountsZero
                    ? ["#ccc"]
                    : [
                        columnColors.yet_to_start,
                        columnColors.inprogress,
                        columnColors.completed,
                        columnColors.download,
                        columnColors.apierror,
                        columnColors.regenerated,
                    ],
                is3D: true,
            },
            rootProps: {
                "data-testid": "1",
            },
        },
    };

    return (
        <Card>
            {/* Title at the Top */}
            <div style={{ padding: "1rem"}}>
                <H3 className="m-0">Over All File Status</H3>
            </div>

            <CardHeader>
                <div className="header-top">
                    <Col xl={12}>
                        {(localStorage.UserType === 'MOS' || localStorage.UserType === 'Vendor') && (
                            <div style={{ display: 'flex', alignItems: "center", gap: "20px", width: "100%" }}>
                                {/* Select Client */}
                                <div style={{ flex: 1 }}>
                                    <Label className="form-label" style={{ fontSize: "110%", display: "block" }}>
                                        Select Client
                                    </Label>
                                    <Controller
                                        name="client"
                                        control={control}
                                        render={({ field }) => (
                                            <Select
                                                {...field}
                                                isClearable
                                                options={clientOptions}
                                                classNamePrefix="select"
                                                placeholder="Client Names"
                                                className={classnames("form-control")}
                                                styles={{
                                                    control: (styles) => ({
                                                        ...styles,
                                                        width: "100%",
                                                        height: '100%',
                                                        borderColor: "black",
                                                    }),
                                                    placeholder: (styles) => ({
                                                        ...styles,
                                                        textAlign: "center",
                                                    }),
                                                }}
                                                value={selectedClient ? { value: selectedClientId, label: selectedClient } : null}
                                                onChange={(selectedOption) => {
                                                    handleSelectClientChange(selectedOption as SingleValue<IClientOption>);
                                                    field.onChange(selectedOption);
                                                }}
                                            />
                                        )}
                                    />
                                </div>

                                {/* Select Date */}
                                <div style={{ flex: 1 }}>
                                    <Label className="form-label" style={{ fontSize: "110%", display: "block" }}>
                                        Select Date
                                    </Label>
                                    <div style={{width:'100%'}}>
                                    <DatePicker
                                        selected={selectedDate}
                                        onChange={(date) => setSelectedDate(date)}
                                        dateFormat="MM-dd-yyyy"
                                        className="form-control"
                                        maxDate={new Date()}
                                
                                        />
                                    </div>
                                </div>
                            </div>

                        )}
                    </Col>
                </div>
                <Row>
                    <H3>
                        Total File Count: {totalFilesCount.length}
                    </H3>
                </Row>
            </CardHeader>

            <CardBody>
                <div className="chart-overflow" id="pie-chart1">
                    <Chart
                        width={googleChartDatas.GooglePieChart1.width}
                        height={googleChartDatas.GooglePieChart1.height}
                        chartType={"PieChart"}
                        loader={<div>{"Loading Chart"}</div>}
                        data={googleChartDatas.GooglePieChart1.data}
                        options={googleChartDatas.GooglePieChart1.option}
                        rootProps={googleChartDatas.GooglePieChart1.rootProps}
                    />
                </div>
            </CardBody>
        </Card>

    );
};

export default OrderStatus;
