
import { AesDecrypt, AesEncrypt } from '../../Service/crypto'

import React, { useState } from "react";
import { Container, Row, Col, Form, FormGroup, Label, Input, Button, Alert } from "reactstrap";
import { useForm, SubmitHandler, Controller } from "react-hook-form";
import { Link, useNavigate } from "react-router-dom";
import MD5 from "crypto-js/md5"; // Import MD5 directly from its specific module
import { Password, RememberPassword, ForgotPassword } from '../../utils/Constant';
import webservice from '../../Service/webservice'; // Make sure to import this correctly
import MySwal from 'sweetalert2';

interface FormData {
  password: string;
  loginEmail: string;
}

const defaultValues: FormData = {
  password: "",
  loginEmail: "",
};

const Login: React.FC = () => {
  const navigate = useNavigate();
  const [show, setShow] = useState(false);
  const [otherDeviceLoggedMsg, setOtherDeviceLoggedMsg] = useState<boolean>(false);
  const [deviceLogoutSuccessMsg, setDeviceLogoutSuccessMsg] = useState<boolean>(false);
  const [loggedOutUserId, setLoggedOutUserId] = useState<string>("");

  const [Id, setId] = useState<string>("");
  const [token, setToken] = useState<string>("");
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);


  const [signInDisabled, setSignInDisabled] = useState<boolean>(false);
  const [commonError, setCommonError] = useState<string>("");

  const { handleSubmit, control, setError, formState: { errors } } = useForm<FormData>({ defaultValues });

  const onSubmit: SubmitHandler<FormData> = async (formData) => {
    setSignInDisabled(true);
    setIsSubmitting(true);

    setCommonError("");
    try {

      if (Object.values(formData).every((field) => field.length > 0)) {
        const hashedPassword = MD5(formData.password).toString();
        formData.password = hashedPassword;
        const EncParams = AesEncrypt(JSON.stringify(formData));
        const result = await fetch(webservice + "api/Authentication/login", {
          method: "POST",
          body: JSON.stringify(EncParams),
          headers: {
            "Content-type": "application/json; charset=UTF-8",
          },
        });
        const response = await result.json();
        const res = AesDecrypt(response);
        if (typeof res === "string" && res === "Invalid password") {
          setSignInDisabled(false);
          setIsSubmitting(false);
          setError("password",{message:"Please enter valid Password"});
        } else if (typeof res === "string" && res === "Invalid email or password") {
          setSignInDisabled(false);
          setIsSubmitting(false);
          setError("loginEmail",{message:"Please enter valid Email"});
          setError("password",{message:"Please enter valid Password"});
        } 
        else {
          
          setToken(res.web_token);
          setId(res.id);
          if (res.login_status === 'Y') {
            setOtherDeviceLoggedMsg(true);
            setLoggedOutUserId(res.id);
            setIsSubmitting(false);
          } else {
            localStorage.setItem("Pin", res["Pin"]);
            localStorage.setItem("UserType", res["UserType"]);
            localStorage.setItem("UserName", res["UserName"]);
            localStorage.setItem("ClientName", res["Client"]);
            if (res["ClientId"] !== "") {
              localStorage.setItem("ClientId", res["ClientId"]);
            }
            if (res["VendorId"] !== "") {
              localStorage.setItem("VendorId", res["VendorId"]);
            }
            localStorage.setItem("emailId", res["emailId"]);
            localStorage.setItem("MobileNo", res["MobileNo"]);
            localStorage.setItem("Location", res["Location"]);
            localStorage.setItem("Role", res["Role"]);
            localStorage.setItem("RoleId", res["RoleId"]);
            localStorage.setItem("WebToken", res["web_token"]);
            localStorage.setItem("id", res["id"]);
            localStorage.setItem("ability", JSON.stringify(res["ability"]));
            return MySwal.fire({
              title: 'Insurance Verification Platform ',
              text: 'We just sent Your Pin to your email',
              icon: 'success',
              customClass: {
                confirmButton: 'btn btn-primary',
              },
              buttonsStyling: false,
              allowOutsideClick: false,
            }).then((result) => {
              if (result.isConfirmed) {
                navigate(`/authentication/pin`);
              }
            });
          }
        }
      } else {
        setSignInDisabled(false);
        setIsSubmitting(false);

        for (const key in formData) {
          if (formData[key as keyof FormData].length === 0) {
            setError(key as keyof FormData, {
              type: "required",
              // message: "This field is required",
            });
          }
        }

      }
    } catch (err) {
      console.error(err);
      setIsSubmitting(false);

    }
  };


  const signout = async (userId: string) => { // Accept userId as a parameter
    setSignInDisabled(false)
    const EncParams = AesEncrypt(JSON.stringify({ Id, token }));

    await fetch(webservice + 'api/Authentication/updateLogoutStatus', {
      method: 'POST',
      body: JSON.stringify({ EncParams }),
      headers: {
        'Content-type': 'application/json; charset=UTF-8',
      }
    })
      .then(result => result.json())
      .then(res => {
        localStorage.setItem('web_token', res.web_token)
        setOtherDeviceLoggedMsg(false)
        setDeviceLogoutSuccessMsg(true)
      });
  }

  return (
    <Container fluid className="p-0">
      <Row className="m-0">
        <Col xs={12} className="p-0">
          <div className="login-card login-dark">
            <div>
              <div className="login-main">
                <Form className="theme-form" onSubmit={handleSubmit(onSubmit)}>
                  <h3>Sign In to Your Account</h3>
                  <p>Enter your email & password to login</p>
                  <FormGroup>
                    <Label className="col-form-label">Email Address</Label>
                    <Controller
                      name="loginEmail"
                      control={control}
                      defaultValue=""
                      render={({ field }) => (
                        <>
                          <Input
                            type="email"
                            required
                            invalid={errors.loginEmail && true}
                            placeholder="Enter your Email"
                            {...field}
                          />
                          {errors.loginEmail &&  <div className="text-danger">{errors.loginEmail.message}</div>}
                          {errors.loginEmail && errors.loginEmail.type === 'required' && (
                              <div className="text-danger">Please enter Your Email</div>
                            )}
                        </>
                      )}
                    />
                  </FormGroup>
                  <FormGroup>
                    <Label className="col-form-label">{Password}</Label>
                    <div className="form-input position-relative">
                      <Controller
                        name="password"
                        control={control}
                        defaultValue=""
                        render={({ field }) => (
                          <>
                            <Input
                              type={show ? "text" : "password"}
                              placeholder="*********"
                              invalid={errors.password && true}
                              {...field}
                            />
                              {errors.password &&  <div className="text-danger">{errors.password.message}</div>}
                            {errors.password && errors.password.type === 'required' && (
                              <div className="text-danger">Please enter a Password</div>
                            )}
                       
                          </>
                        )}
                      />
                      {!errors.password && (<div className="show-hide">
                  <span onClick={() => setShow(!show)} className={!show ? "show" : ""}/>
                </div>)}
                    </div>
                  </FormGroup>
                  {otherDeviceLoggedMsg && (
                    <Alert color='danger' className='cursor-pointer text-danger'>
                      <b>Alert!</b> This account seems to be open in another system.
                      <button
                        className="btn-link"
                        onClick={async () => await signout(loggedOutUserId)}
                        style={{
                          background: 'none',
                          border: 'none',
                          padding: 0,
                          margin: 0,
                          color: 'blue',
                          textDecoration: 'underline',
                          cursor: 'pointer'
                        }}
                      >
                        <u>Click here to sign out from all other web sessions and sign in on this system.</u>
                      </button>
                    </Alert>
                  )}
                  {deviceLogoutSuccessMsg && <Alert color='success' className='cursor-pointer '> <b>Alert!</b> Logged Out Successfully, You can Login Now </Alert>}
                  <FormGroup className="mb-0 form-sub-title">
                  <div className="checkbox p-0">
                      <Input id="checkbox1" type="checkbox" />
                      {/* <Label className="text-muted" htmlFor="checkbox1">{RememberPassword}</Label> */}
                    </div> 
                    <Link className="link" to={`/authentication/forgetpassword`} >{ForgotPassword} </Link>
                    <div className="text-end mt-3">
                      <Button color="primary" className="btn-block w-100" type="submit" disabled={signInDisabled}>
                        {isSubmitting ? (
                          <img src="../assets/images/ajax-loader.gif" alt="Loading..." />
                        ) : (
                          'Sign In'
                        )}
                      </Button>
                    </div>
                  </FormGroup>
                </Form>
              </div>
            </div>
          </div>
        </Col>
      </Row>
    </Container>
  );
}

export default Login;