import React, { useState, useEffect, Fragment, useMemo, InputHTMLAttributes, forwardRef, useCallback } from 'react';
import { Button, Row, Col, Card, CardBody, Label, FormFeedback, Input, InputGroup, Nav, UncontrolledTooltip, InputGroupText, Modal, ModalBody } from 'reactstrap';
import Select, { SingleValue } from 'react-select';
import classnames from "classnames";
import { useDispatch, useSelector } from 'react-redux';
import { ChevronLeft, ChevronRight, Download, Edit, File, FileText, LogIn, Trash2, X, } from 'react-feather';
import { Controller, useForm } from 'react-hook-form';
import Swal from 'sweetalert2';
import axios from 'axios';
import DataTable from 'react-data-table-component';
import webservice from '../../../Service/webservice';
import { AesDecrypt, AesEncrypt } from '../../../Service/crypto';
import 'flatpickr/dist/themes/material_blue.css'; // Import the CSS for the theme you want to use
import { useNavigate } from 'react-router-dom';
import { saveAs } from 'file-saver'
import { useAppDispatch } from '../../../ReaduxToolkit/Hooks';
import ReactPaginate from 'react-paginate';
import { getManualData } from './store';
import websocket_webservice from '../../../Service/websocket_webservice'
import ExcelJS from 'exceljs';
import DatePicker from "react-datepicker";
import { FaCalendarAlt } from 'react-icons/fa';
import moment from 'moment-timezone';

interface DeleteRowData {
    Patientname: string;
    id: string;
    PatientDOB: string;
    Memberid: string;
    client_name: string;
    uploaded_date: string;
    RefId: string;
    Payor_ID: string;
    Provider_NPI: string;
    template_mapping_time: string;
    xml_conversion_time: string;
    execution_time: string;
    Payer_ID: string;
    Provider_Name: string;
    Provider_ID: string;
    Payer_Name: string;
    Data_Fetched: string;
    xml_conversion: string;
    error_message: string;
    error_reason: string;
    template: string;
    FeeSchedule: string;
    regen_status: string;
    UserName: string;
    Type: string;
    Payer_name: string;
}

interface IDefaultValues {
    DoB: string;
    firstName: string;
    MiddleName?: string;
    lastName: string;
    Memberid: string;
    // PayorID: string;
    ProviderNPI: string;
    ClientName: string;
    Insurancename: string;
    Subscriber: string;
    Address: string;
    FeeSchedule: string;
}

interface Iinsuranceoption {
    label: string;
    value: string;
}

interface Addresseoption {
    label: string;
    value: string;
}

const defaultValues: IDefaultValues = {
    DoB: '',
    firstName: '',
    MiddleName: '',
    lastName: '',
    Memberid: '',
    // PayorID: '',
    ProviderNPI: '',
    ClientName: '',
    Insurancename: '',
    Subscriber: '',
    Address: '',
    FeeSchedule: "",
};

type PatientData = {
    First_Name: string;
    MiddleName: string;
    Last_Name: string;
    Memberid: string;
    Type: string;
    PatientDOB: string;
    Provider_NPI: string;
    Payer_name: string;
};


interface IClientOption {
    label: string;
    value: string;
}

interface IOption {
    value: string;
    label: string;
}

interface FeeScheduleOption {
    value: string;
    label: string;
}


const SubscriberOptions: IOption[] = [
    { value: 'subscriber', label: 'subscriber' },
    { value: 'dependent', label: 'Dependent' },
];

const FeeScheduleOption: IOption[] = [
    { value: 'PPO', label: 'PPO' },
    { value: 'PREMIER', label: 'PREMIER' },
];

const CustomHeader = ({ handleFilter, value, handlePerPage, rowsPerPage }: any) => {
    return (
        <div className='client-master-list-table-header w-100 py-2'>
            <Row>
                <Col lg='6' className='d-flex align-items-center px-0 px-lg-1'>
                    <div className='d-flex align-items-center me-4 '>
                        <label htmlFor='rows-per-page' className='me-2'>Rows per Page:</label>
                        <Input
                            type='select'
                            id='rows-per-page'
                            value={rowsPerPage}
                            onChange={handlePerPage}
                            className='form-control ms-50 larger-select '
                        >
                            <option value='10'>10</option>
                            <option value='25'>25</option>
                            <option value='50'>50</option>
                        </Input>
                    </div>
                </Col>
                <Col
                    lg='6'
                    className='d-flex align-items-center justify-content-lg-end mt-lg-0 mt-1 px-0 px-lg-3'
                >
                    <InputGroup className="input-group-search mb-2 zoom-on-hover">
                        <Input
                            type="text"
                            placeholder="🔍 Search...."
                            value={value}
                            onChange={(e) => handleFilter(e.target.value.trim())} // Trim whitespace before filtering
                            className="input-search"
                        />
                    </InputGroup>

                </Col>
            </Row>
        </div>
    );
};

const Bulkupload: React.FC = () => {
    const { control, handleSubmit, formState: { errors }, reset } = useForm<IDefaultValues>({
        defaultValues,
    });


    const [selectedClientId, setSelectedClientId] = useState<string>('');
    const [selectedClient, setSelectedClient] = useState<string>('');
    const [selectedtemplate, setselectedtemplate] = useState<string>('');
    const [selectedtemplateId, setselectedtemplateId] = useState<string>('');
    const [SelectedInsurancename, setSelectedInsurance] = useState<string>('');
    const [SelectedInsuranceId, setSelectedInsuranceId] = useState<string>('');
    const [SelectedAddressname, setSelectedAddress] = useState<string>('');
    const [SelectedAddressId, setSelectedAddressId] = useState<string>('');
    const Username = localStorage.UserName;
    const [UserType] = useState<string>(localStorage.UserType);
    const [Id, setId] = useState<string>('');
    const [isOpen, setIsOpen] = useState<boolean>(false);
    const [isOpenpatient, setIsOpenpatient] = useState<boolean>(false);
    const [editOpen, setIsEditOpen] = useState<boolean>(false);
    const [editData, setIsEditData] = useState<PatientData | null>(null);
    const [editId, setEditId] = useState<string>('');
    const toggle = () => setIsOpen(!isOpen);
    const togglepatient = () => setIsOpenpatient(!isOpenpatient);
    const navigate = useNavigate();

    const [formValues, setFormValues] = useState({
        firstName: "",
        middleName: "",
        lastName: "",
        memberId: "",
        patientType: "",
        dob: "",
        providerNpi: "",
        insurance: "",
        feeSchedule: "",
    });

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setFormValues({
            ...formValues,
            [e.target.name]: e.target.value,
        });
    };

    useEffect(() => {
        if (UserType !== 'MOS' && UserType !== 'Vendor') {
            setSelectedClient(localStorage.getItem('ClientName') || '');
            setSelectedClientId(localStorage.getItem('ClientId') || '');
        }
    }, [UserType]);
    useEffect(() => {
        if (UserType === 'Vendor') {
            setId(localStorage.getItem('VendorId') || '');
        } else
            if (UserType === 'Client') {
                setId(localStorage.getItem('ClientId') || '');
            }
            else if (UserType === 'MOS') {
                setId(localStorage.getItem('id') || '');
            }
    }, [UserType]);


    const [clientOptions, setClientOptions] = useState<IClientOption[]>([]);
    const [vendorbasedclientOptions, setvendorbasedclientOptions] = useState<IClientOption[]>([]);

    const [templateOptions, settemplateOptions] = useState<IClientOption[]>([]);
    const [insuranceoption, setInsurance] = useState<[]>([]);
    const [Addresseoption, setAddress] = useState<[]>([]);
    const [eventId, setEventId] = useState<DeleteRowData[]>([]);
    const [eventId1, setEventId1] = useState(0);
    const selectedRowsPerPage: { [key: number]: DeleteRowData[] } = useMemo(() => ({}), []);
    const menuActions = ['add', 'delete'];
    const action = { fromUser: true };
    const dispatch = useAppDispatch()
    const store = useSelector((state: any) => state.ManualEntries)
    const [value, setValue] = useState<string>('');
    const [sort, setSort] = useState<string>('');
    const [sortColumn, setSortColumn] = useState<any>('uploaded_date'); // Example initial sort column
    const [currentPage, setCurrentPage] = useState<number>(1);
    const [rowsPerPage, setRowsPerPage] = useState<number>(10);
    const [localData, setLocalData] = useState<DeleteRowData[]>(store.data);

    const [otpId, setOtpId] = useState<string>('');

    const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
    const [isOtpModalOpen, setIsOtpModalOpen] = useState<boolean>(false);
    const [enteredOtp, setEnteredOtp] = useState("");
    const [patientData, setPatientData] = useState<any>(null);
    const [viewingImage, setViewingImage] = useState<any>(false);

    
     // Effect for fetching data
        const fetchData = async () => {
            if (UserType && Id !== undefined) {
                const actionResult = await dispatch(
                    getManualData({
                        Id,
                        q: value,
                        perPage: rowsPerPage,
                        page: currentPage,
                        sort,
                        sortColumn,
                    })
                );
                // Type assertion to inform TypeScript about the structure of actionResult.payload
                const payload = actionResult.payload as { data: DeleteRowData[] };
                // Update local state with fetched data
                setLocalData(payload.data);
            }
        };
    
        // useEffect for fetching data when dependencies change
        useEffect(() => {
            fetchData();
        }, [dispatch, UserType, Id, sort, value, sortColumn, currentPage, rowsPerPage]);
    
        // Effect for handling WebSocket connection
        useEffect(() => {
            const socket = new WebSocket(`${websocket_webservice}ws/BulkuploadPatient/`);
            // socket.onopen = () => console.log("WebSocket connected");
            socket.onmessage = (event) => {
                const change = JSON.parse(event.data);
                // console.log('change', change);
                
                // Update the local state with the real-time change
                setLocalData((prevData: DeleteRowData[]) =>
                    prevData.map((item) => {
                        if (item.id === change.documentKey._id.$oid) {
                            return { ...item, ...change.updateDescription.updatedFields };
                        }
                        return item;
                    })
                );
            };
    
            // Clean up: close the WebSocket connection when the component unmounts
            return () => {
                if (socket.readyState === WebSocket.OPEN) {
                    socket.close();
                }
            };
        }, [websocket_webservice]); // Add any additional dependencies if necessary


    const handleReloadTable = () => {
        dispatch(
            getManualData({
                Id,
                UserType,
                q: value,
                perPage: rowsPerPage,
                page: currentPage,
                sort,
                sortColumn
            })
        );
    };


    const handleFilter = (val: any) => {
        setValue(val)
        setCurrentPage(1)
        dispatch(
            getManualData({
                Id,
                UserType,
                q: val,
                perPage: rowsPerPage,
                page: currentPage,
                sort,
                sortColumn,
            })
        )
    }

    const handlePerPage = (e: any) => {
        setCurrentPage(1)
        dispatch(
            getManualData({
                Id,
                UserType,
                q: value,
                perPage: parseInt(e.target.value),
                page: currentPage,
                sort,
                sortColumn,
            })
        )
        setRowsPerPage(parseInt(e.target.value))
    }



    const handlePagination = (page: any) => {
        dispatch(
            getManualData({
                Id,
                UserType,
                q: value,
                perPage: rowsPerPage,
                page: page.selected + 1,
                sort,
                sortColumn,
            })
        )
        setCurrentPage(page.selected + 1)
    }

    const dataToRender = () => {
        const filters: any = {
            q: value
        };
        const isFiltered = Object.keys(filters).some(function (k) {
            return filters[k].length > 0;
        });

        if (localData.length > 0) {
            return localData;
        } else if (localData.length === 0 && isFiltered) {
            return [];
        } else {
            return store.allData.slice(0, rowsPerPage);
        }
    };


    const handleSort = (column: any, sortDirection: any) => {
        setSort(sortDirection)
        setSortColumn(column.sortField)
        dispatch(
            getManualData({
                Id,
                UserType,
                q: value,
                perPage: rowsPerPage,
                page: currentPage,
                sort: sortDirection,
                sortColumn: column.sortField
            })
        )
    }

    const CustomPagination = () => {
        const count = Number(Math.ceil(store.total / rowsPerPage))

        return (
            <ReactPaginate
                previousLabel={<ChevronLeft size={16} />}
                nextLabel={<ChevronRight size={16} />}
                breakLabel="..."
                pageCount={count || 1}
                onPageChange={handlePagination}
                forcePage={currentPage !== 0 ? currentPage - 1 : 0}
                containerClassName="pagination-container"
                pageClassName="page-item"
                pageLinkClassName="page-link"
                previousClassName="page-item"
                previousLinkClassName="page-link"
                nextClassName="page-item"
                nextLinkClassName="page-link"
                breakClassName="page-item"
                breakLinkClassName="page-link"
                activeClassName="active"
            />
        )
    }

    //OTP Process 
    const OTP = async () => {
        const response = await fetch(webservice + 'api/BulkUpload/otp', {
            method: 'POST',
            headers: {
                'Content-type': 'application/json; charset=UTF-8',
            },
        }).then((response) => response.json());

        const TempData = AesDecrypt(response);
        return TempData;
    };

    useEffect(() => {
        const fetchOTP = async () => {
            const response = await OTP();
            if (response.length > 0 && response[0].id) {
                setOtpId(response[0].id);
            } else {
            }
        };
        fetchOTP();
    }, []);

    useEffect(() => {
        if (otpId) {
            setIsOtpModalOpen(true);
        }
    }, [otpId]);

    const handleSubmitOtp = async () => {
        const Otpdata = { Id: otpId, Otp: enteredOtp };
        try {
            const EncParams = AesEncrypt(JSON.stringify(Otpdata)); // Convert object to string before encryption
            const response = await fetch(webservice + "api/updateOtp", {
                method: "POST",
                body: JSON.stringify({ EncParams }),
                headers: {
                    "Content-type": "application/json; charset=UTF-8",
                },
            });
            const responseData = await response.json();
            if (responseData === "OTP Updated Successfully") {
                Swal.fire({
                    icon: "success",
                    title: "Insurance Verification Platform",
                    text: "OTP send Successfully",
                    customClass: {
                        confirmButton: "btn btn-success",
                    },
                    allowOutsideClick: false
                });
            }

            setIsOtpModalOpen(false); // Close modal after submission
        } catch (error) {
            console.error("Error submitting OTP: ", error);
        }
    };

    const get_companyname = async () => {
        const response = await fetch(webservice + 'api/BulkUpload/companyname', {
            method: 'POST',
            headers: {
                'Content-type': 'application/json; charset=UTF-8',
            },
        }).then((response) => response.json());

        const TempData = AesDecrypt(response);
        return TempData;
    };

    useEffect(() => {
        const fetchRoleId = async () => {
            const response = await get_companyname();
            const res = response.map((data: any) => ({
                label: data.CompanyName,
                value: data.id,
            }));
            setClientOptions(res);
        };

        // Fetch the initial data
        fetchRoleId();

        // WebSocket setup
        const socket = new WebSocket(`${websocket_webservice}ws/ClientDropdown/`);

        // socket.onopen = () => console.log('WebSocket connected');
        // socket.onclose = () => console.log('WebSocket closed');
        // socket.onerror = (error) => console.error('WebSocket error:', error);

        socket.onmessage = () => {
            // const change = JSON.parse(event.data);
            // console.log('WebSocket message received:', change);

            // Optionally, re-fetch the data to update the state when a WebSocket message is received
            fetchRoleId();
        };

        // Clean up the WebSocket connection when the component unmounts
        return () => {
            if (socket.readyState === 1) { // <-- This is important
                socket.close();
            }
        }
    }, []);

    const get_companynamevendorbased = async () => {
        const EncParams = AesEncrypt(JSON.stringify({ id: localStorage.VendorId }));

        const response = await fetch(webservice + 'api/BulkUpload/vendorbasedclientOptions', {
            method: 'POST',
            body: JSON.stringify({ EncParams }),
            headers: {
                'Content-type': 'application/json; charset=UTF-8',
            },
        }).then((response) => response.json());

        const TempData = AesDecrypt(response);
        return TempData;
    };

    useEffect(() => {
        const vendorbasedfetchRoleId = async () => {
            const response = await get_companynamevendorbased();
            const res = response.map((data: any) => ({
                label: data.CompanyName,
                value: data.id,
            }));
            setvendorbasedclientOptions(res);
        };

        // Fetch the initial data
        vendorbasedfetchRoleId();

        // WebSocket setup
        const socket = new WebSocket(`${websocket_webservice}ws/ClientDropdown/`);

        // socket.onopen = () => console.log('WebSocket connected');
        // socket.onclose = () => console.log('WebSocket closed');
        // socket.onerror = (error) => console.error('WebSocket error:', error);

        socket.onmessage = () => {
            // const change = JSON.parse(event.data);
            // console.log('WebSocket message received:', change);

            // Optionally, re-fetch the data to update the state when a WebSocket message is received
            vendorbasedfetchRoleId();
        };

        // Clean up the WebSocket connection when the component unmounts
        return () => {
            if (socket.readyState === 1) { // <-- This is important
                socket.close();
            }
        }
    }, []);

    const get_Insurance = async () => {
        const response = await fetch(webservice + 'api/insurancename', {
            method: 'POST',
            headers: {
                'Content-type': 'application/json; charset=UTF-8',
            },
        }).then((response) => response.json());

        const TempData = AesDecrypt(response);
        return TempData;
    };

    useEffect(() => {
        const fetchRoleId = async () => {
            const response = await get_Insurance();
            const res = response.map((data: any) => ({
                label: data.name,
                value: data.name,
            }));
            setInsurance(res);
        };

        // Fetch the initial data
        fetchRoleId();

        // WebSocket setup
        const socket = new WebSocket(`${websocket_webservice}ws/InsuranceDropdown/`);

        // socket.onopen = () => console.log('WebSocket connected');
        // socket.onclose = () => console.log('WebSocket closed');
        // socket.onerror = (error) => console.error('WebSocket error:', error);

        socket.onmessage = () => {
            // const change = JSON.parse(event.data);
            // console.log('WebSocket message received:', change);

            // Optionally, re-fetch the data to update the state when a WebSocket message is received
            fetchRoleId();
        };

        // Clean up the WebSocket connection when the component unmounts
        return () => {
            if (socket.readyState === 1) { // <-- This is important
                socket.close();
            }
        }
    }, []);

    const [Screenshot_status, setScreenshot_status] = useState("No");

    const handleScreenshot_statusChange = (e: any) => {
        setScreenshot_status(e.target.value);
    };

    const handleSelectClientChange = (selectedOption: SingleValue<IClientOption>) => {
        if (selectedOption) {
            setSelectedClient(selectedOption.label);
            setSelectedClientId(selectedOption.value);

            // Clear address and template when client is changed
            setSelectedAddress(''); // Reset address
            setSelectedAddressId('');
            setAddress([]); // Assuming setAddress is setting address options
            setselectedtemplate(''); // Reset template
            setselectedtemplateId('');
        } else {
            // Reset all fields if no client is selected
            setSelectedClient('');
            setSelectedClientId('');
            setSelectedAddress('');
            setSelectedAddressId('');
            setselectedtemplate('');
            setselectedtemplateId('');
            setAddress([]); // Reset the address options
        }
    };


    const handleSelecttemplateChange = (selectedOption: SingleValue<IClientOption>) => {
        if (selectedOption) {
            setselectedtemplate(selectedOption.label);
            setselectedtemplateId(selectedOption.value);
        } else {

            setselectedtemplate('');
            setselectedtemplateId('');
        }
    };

    const handleinsuranceChange = (selectedOption: SingleValue<Iinsuranceoption>) => {
        if (selectedOption) {
            setSelectedInsurance(selectedOption.label);
            setSelectedInsuranceId(selectedOption.value);
        } else {
            setSelectedInsurance('');
            setSelectedInsuranceId('');
        }
    };

    const handleAddressChange = (selectedOption: SingleValue<Addresseoption>) => {
        if (selectedOption) {
            setSelectedAddress(selectedOption.label);
            setSelectedAddressId(selectedOption.value);
        } else {
            setSelectedAddress('');
            setSelectedAddressId('');
        }
    };


    const template = async () => {
        try {

            const EncParams = AesEncrypt(JSON.stringify({ selectedClientId }));
            const response = await fetch(webservice + 'api/BulkUpload/template', {
                method: 'POST',
                body: JSON.stringify({ EncParams }),
                headers: {
                    'Content-type': 'application/json; charset=UTF-8',
                },
            });
            const result = await response.json();
            const decryptedResult = AesDecrypt(result);
            const res = decryptedResult.map((data: any) => ({
                label: data.file_name,
                value: data.id,
            }));
            settemplateOptions(res);
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };

    useEffect(() => {
        template();
    }, [selectedClientId]);

    useEffect(() => {
        const get_Address = async () => {
            if (!selectedClientId) {
                return;
            }

            try {
                const EncParams = AesEncrypt(JSON.stringify({ selectedClientId }));
                const fetchResponse = await fetch(webservice + 'api/address', {
                    method: 'POST',
                    body: JSON.stringify({ EncParams }),
                    headers: {
                        'Content-type': 'application/json; charset=UTF-8',
                    },
                });

                const data = await fetchResponse.json();
                const data1 = AesDecrypt(data);

                const res = data1.flatMap((item: any) =>
                    item.Address.map((address: string) => ({
                        label: address,
                        value: address,
                    }))
                );

                setAddress(res);
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };

        // Fetch the initial address data
        get_Address();

        // WebSocket setup
        const socket = new WebSocket(`${websocket_webservice}ws/ClientDropdown/`);

        // socket.onopen = () => console.log('WebSocket connected');
        // socket.onclose = () => console.log('WebSocket closed');
        // socket.onerror = (error) => console.error('WebSocket error:', error);

        socket.onmessage = () => {
            // const change = JSON.parse(event.data);
            // console.log('WebSocket message received:', change);

            // Optionally, re-fetch the data to update the state when a WebSocket message is received
            get_Address();
        };

        // Clean up the WebSocket connection when the component unmounts
        return () => {
            if (socket.readyState === 1) { // <-- This is important
                socket.close();
            }
        }
    }, [selectedClientId]);



    const deletePatient = async (ids: any[]) => {
        const EncParams = AesEncrypt(JSON.stringify(ids));
        await fetch(webservice + 'api/bulkupload/deletePatientdetails', {
            method: 'POST',
            body: JSON.stringify({ EncParams }),
            headers: {
                'Content-type': 'application/json; charset=UTF-8',
            }
        });
    };

    const DeleteAuditLogdata = async (data: any[]) => {
        data.forEach(item => {
            item.Userid = localStorage.id;
            item.ParentUserId = localStorage.ParentUserId;
        });
        const EncParams = AesEncrypt(JSON.stringify(data));
        const response = await fetch(webservice + 'api/bulkupload/deleteauditlog', {
            method: 'POST',
            body: JSON.stringify({ EncParams }),
            headers: {
                'Content-type': 'application/json; charset=UTF-8',
            }
        }).then(response => response.json());
        return response;
    };

    const DeleteRow = async (rows: any[]) => {
        const ids = rows.map(row => row.id);
        const auditData = rows.map(row => ({
            Username,
            RefId: row.RefId,
            Name: row.Patientname
        }));

        const deleteRow = async () => {
            Swal.fire({
                title: "Insurance Verification Platform",
                text: "Are you sure do you want to delete?",
                icon: "warning",
                showCancelButton: true,
                confirmButtonText: "Yes",
                customClass: {
                    confirmButton: "btn btn-primary",
                    cancelButton: "btn btn-danger ms-1",
                },
                buttonsStyling: false,
                allowOutsideClick: false
            })
                .then(async function (result) {
                    if (result.isConfirmed) {
                        await deletePatient(ids);
                        const res = await DeleteAuditLogdata(auditData);
                        Swal.fire({
                            icon: "success",
                            title: "Insurance Verification Platform",
                            text: "Selected patient details have been deleted.",
                            customClass: {
                                confirmButton: "btn btn-success",
                            },
                            allowOutsideClick: false
                        });
                        handleReloadTable();
                    }
                });
        };
        deleteRow();
    };


    const [isDownloading, setIsDownloading] = useState(null);

    const handleOpenWordDocument = async (id: any, patient: any) => {
        setIsDownloading(id);
        try {
            const EncParams = AesEncrypt(JSON.stringify({ id: id! })); // Add a non-null assertion here
            const response = await fetch(webservice + 'api/getPatientFile_word', {
                method: 'POST',
                body: JSON.stringify({ EncParams }),
                headers: {
                    'Content-type': 'application/json; charset=UTF-8',
                }
            });
            const responseData = await response.json();
            const OutData = AesDecrypt(responseData)

            const docxData = Uint8Array.from(atob(OutData.file), (c) => c.charCodeAt(0));
            const blob = new Blob([docxData], {
                type: "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
            });
            const filename = OutData.filename
            saveAs(blob, filename + '.docx')
            setIsDownloading(null);

            if (!OutData.file) {
                throw new Error('No file data in response');
            }
        } catch (error) {
            console.error('Error downloading file: ', error);
        }
    };

    const handleErrorClick = async (row: any) => {
        const patientId = row.id
        setLoading(true);
        try {
            const EncParams = AesEncrypt(patientId);
            const response = await fetch(webservice + "api/Email-fetchErrorDatas", {
                method: "POST",
                body: JSON.stringify({ EncParams }),
                headers: { "Content-Type": "application/json; charset=UTF-8" },
            }).then((res) => res.json());
            setLoading(false);
            setIsModalOpen(true);
            setPatientData(response[0]);
        } catch (error) {
            console.error("Error fetching patient data:", error);
        } finally {
            setLoading(false); // Hide loading after fetching data
        }

    };
    const handleregenrateDocument = async (row: any) => {
        setLoading(true);
        try {
            // Ensure row.id is defined and not null
            if (!row || !row.id) {
                throw new Error("Invalid row data: id is undefined or null.");
            }

            // Ensure id is treated as a string
            const id1 = [row.id.toString()]; // Create an array with the id as a string

            const EncParams = AesEncrypt(JSON.stringify({ ids: id1 }));
            const fetchResponse = await fetch(webservice + 'api/regenratepatients', {
                method: 'POST',
                body: JSON.stringify({ EncParams }),
                headers: {
                    'Content-type': 'application/json; charset=UTF-8',
                },
            });
            const data = await fetchResponse.json();
            const data1 = AesDecrypt(data);

            // First API call to check the condition
            const api = await axios.get(webservice + 'api/elligibilityapi');
            if (api.data === 'done') {
                const xmlcreation = await axios.get(webservice + 'api/elligibilityapi/Mapping');
                // console.log('XML creation triggered:', xmlcreation.data);
            } else {
                // console.log('API response is not "done". No XML creation triggered.');
            }


            // await axios.get(webservice + 'api/elligibilityapi');

            if (data1 === "S") {
                Swal.fire({
                    icon: "success",
                    title: "Insurance Verification Platform",
                    text: "Patients have been Regenrated.",
                    customClass: {
                        confirmButton: "btn btn-success",
                    },
                    allowOutsideClick: false
                });
                setLoading(false);
                navigate(`/patients`);
            }
        } catch (error) {
            console.error('Error downloading file: ', error);
        }
    };

    interface BootstrapCheckboxProps extends InputHTMLAttributes<HTMLInputElement> {
        id: string;
    }
    // Handle individual row checkbox change
    const BootstrapCheckbox = forwardRef<HTMLInputElement, BootstrapCheckboxProps>(
        ({ id, ...props }, ref) => (
            <div style={{ position: 'relative', left: '10px' }}>
                <input type='checkbox' id={id} ref={ref} {...props} />
            </div>
        )
    );

    const handleChangecheck = useCallback(
        ({ selectedRows }: any) => {
            const selectedEventIds = selectedRows.map((row: any) => row.id);
            setEventId(selectedRows);
            setEventId1(selectedEventIds.length);
            if (!action.fromUser) return;
            selectedRowsPerPage[currentPage] = selectedRows;
        }, [currentPage, selectedRowsPerPage, action.fromUser]
    );


    const handleEdit = async (row: any) => {
        if (!row || typeof row !== "object") return;
        setIsEditOpen(true)
        setIsEditData(row)
        setEditId(row.id)
    };

    useEffect(() => {
        if (editOpen && editData) {
            setFormValues({
                firstName: editData.First_Name || "",
                middleName: editData.MiddleName || "",
                lastName: editData.Last_Name || "",
                memberId: editData.Memberid || "",
                patientType: editData.Type || "",
                dob: editData.PatientDOB || "",
                providerNpi: editData.Provider_NPI || "",
                insurance: editData.Payer_name || "",
                feeSchedule: "", // Set default value if needed
            });
        }
    }, [editOpen, editData]);



    const handleSubmitEditdata = async () => {
        setLoading(true);
        const data = JSON.stringify({ editId, formValues }); // Convert to string before encryption
        const auditLogData = [
            {
                field: 'Patient Name',
                before: `${editData?.First_Name} ${editData?.Last_Name}` || '',
                after: `${formValues.firstName} ${formValues.lastName}`,
            },
            {
                field: 'Date of Birth',
                before: editData?.PatientDOB || '',
                after: formValues.dob,
            },
            {
                field: 'Member ID',
                before: editData?.Memberid || '',
                after: formValues.memberId,
            },
            {
                field: 'Provider NPI',
                before: editData?.Provider_NPI || '',
                after: formValues.providerNpi,
            },
        ];
        const Username = localStorage.getItem('UserName') || '';
        const auditData = { auditLogData, Username };
        try {
            const EncParams = AesEncrypt(data);
            const response = await fetch(webservice + "api/edit_patient_data", {
                method: "POST",
                body: JSON.stringify({ EncParams }),
                headers: { "Content-Type": "application/json; charset=UTF-8" },
            })
            const responseData = await response.json();
            if (responseData === "Patient Updated Successfully") {
                await axios.post(webservice + 'api/BulkUpload/EditauditLogdata', auditData, {
                    headers: { 'Content-type': 'application/json; charset=UTF-8' }
                });
                Swal.fire({
                    icon: "success",
                    title: "Insurance Verification Platform",
                    text: "Patient Updated Successfully",
                    customClass: {
                        confirmButton: "btn btn-success",
                    },
                    allowOutsideClick: false
                });
                setIsEditOpen(false);
                setLoading(false);
                handleReloadTable();
            }
        } catch (error) {
            console.error("Error fetching patient data:", error);
        }
    };

    const Columns = [
        { name: 'Uploaded by', selector: (row: { UserName: string }) => row.UserName ? row.UserName : 'NA', sortable: true, width: "160px" },
        { name: 'Patient Name', selector: (row: { Patientname: string }) => row.Patientname, sortable: true, width: "180px" },
        { name: 'Client Name', selector: (row: { client_name: string }) => row.client_name, sortable: true, width: "160px" },
        { name: 'Template Name', selector: (row: { template: string }) => row.template, sortable: true, width: "200px" },
        { name: 'Patient DOB', selector: (row: { PatientDOB: string }) => row.PatientDOB, sortable: true, width: "160px" },
        { name: 'Member ID', selector: (row: { Memberid: string }) => row.Memberid ? row.Memberid : 'NA', sortable: true, width: "160px" },
        { name: 'Payer ID', selector: (row: { Payor_ID: string }) => row.Payor_ID, sortable: true, width: "160px" },
        { name: 'Insurance Name', selector: (row: { Payer_name: string }) => row.Payer_name, width: "300px", sortable: true },
        { name: 'Type', selector: (row: { Type: string }) => row.Type, sortable: true, width: "160px" },
        { name: 'Provider NPI', selector: (row: { Provider_NPI: string }) => row.Provider_NPI ? row.Provider_NPI : 'NA', sortable: true, width: "160px" },
        {
            name: (
                <>
                    Template <br /> Generation Time
                </>
            ),
            selector: (row: { template_mapping_time: string }) =>
                row.template_mapping_time ? `${row.template_mapping_time} sec` : "NA",
            sortable: true
        },
        {
            name: (
                <>
                    XML <br /> Conversion Time
                </>
            ),
            selector: (row: { xml_conversion_time: string }) =>
                row.xml_conversion_time ? `${row.xml_conversion_time} sec` : "NA",
            sortable: true
        },
        {
            name: (
                <>
                    Execution Time
                </>
            ),
            selector: (row: { execution_time: string }) => row.execution_time ? `${row.execution_time} min` : "NA",
            sortable: true
        },
        { name: 'BOT Status', selector: (row: { Data_Fetched: string }) => row.Data_Fetched === 'Completed' ? 'Received' : row.Data_Fetched || 'NA', sortable: true, width: "160px" },
        {
            name: 'Created date',
            selector: (row: { uploaded_date: string }) => {
                return row.uploaded_date
                    ? moment.utc(row.uploaded_date)
                        .tz(moment.tz.guess())
                        .format('MM/DD/YYYY, HH:mm:ss')
                    : 'NA';
            },
            sortable: true,
            width: "180px"
        },
        {
            name: "View Patient",
            width: "160px",
            sortable: true,
            cell: (row: DeleteRowData) => (
                <>
                    {row.Data_Fetched === "Completed" && (
                        <>
                            <FileText
                                size={17}
                                id='editIcon'
                                // color='#FFA500'
                                color={row.xml_conversion === "C" ? '#FFA500' : '#800080'}
                                onClick={() => {
                                    navigate(`/patient-eligibility/${row.id}?view=ManualPatientView`);
                                }}
                                style={{ cursor: 'pointer', marginRight: '10px' }}
                            />
                            <UncontrolledTooltip placement="top" target="editIcon">
                                View Patient Details
                            </UncontrolledTooltip>
                        </>
                    )}
                    {row.xml_conversion === "C" && (
                        <div onClick={() => handleOpenWordDocument(row.id, row.Patientname)}>
                            {isDownloading === row.id ? (
                                <div className="spinner"></div>
                            ) : (
                                <>
                                    <Download size={20} style={{ cursor: 'pointer' }} id={`downloadIcon-${row.id}`} />
                                    <UncontrolledTooltip placement="top" target={`downloadIcon-${row.id}`}>
                                        Download
                                    </UncontrolledTooltip>
                                </>
                            )}
                        </div>
                    )}
                    {row.Data_Fetched === "Error" && (
                        <>
                            <FileText
                                size={17}
                                id={`error_message_${row.id}`}  // Unique ID for each row
                                color="red"
                                onClick={() => {
                                    handleErrorClick(row);
                                }}
                                style={{ cursor: 'pointer', marginRight: '10px' }}
                            />
                            <UncontrolledTooltip placement="top" target={`error_message_${row.id}`}>
                                <p style={{ color: "#e61616" }}>{row.error_message}</p>
                            </UncontrolledTooltip>
                        </>
                    )}
                    &nbsp;&nbsp;&nbsp;
                    {row.Data_Fetched?.trim() === "Error" && row.error_message?.includes("Error in patient details") && (
                        <>
                            <Edit size={20} style={{ cursor: 'pointer' }} id={`EditIcon-${row.id}`} onClick={() => { handleEdit(row) }} />
                            <UncontrolledTooltip placement="top" target={`EditIcon-${row.id}`}>
                                Edit
                            </UncontrolledTooltip>
                        </>
                    )}

                    &nbsp;&nbsp;&nbsp;
                    {/* {row.Data_Fetched !== "Error" && row.regen_status === "Yes" &&(
                <div>
                    <a className="nav-link" onClick={() => handleregenrateDocument(row)}>
                        <LogIn size={20} style={{ cursor: 'pointer' }} id={`regenIcon-${row.id}`} />
                        <UncontrolledTooltip placement="top" target={`regenIcon-${row.id}`}>
                            Regenerate
                        </UncontrolledTooltip>
                    </a>
                </div>
            )} */}
                </>
            ),
        },
        // {
        //     name: 'Error Reason',
        //     selector: (row: { error_reason: string }) => row.error_reason || 'NA',
        //     sortable: true,
        //     cell: (row: { error_reason: string }) => (
        //         <span 
        //         style={{ color: row.error_reason ? 'red' : 'black' }}
        //         title={row.error_reason}
        //     >
        //         {(row.error_reason ? row.error_reason.split(" ").slice(0, 10).join(" ") : "NA")}
        //         {row.error_reason && row.error_reason.split(" ").length > 10 && "..."}
        //     </span>
        //     )
        //   }
    ]
    const Exportrow = async (rows: any) => {
        setLoading(true);

        // Extract only the necessary columns
        const filteredData = rows.map((row: any) => ({
            OnederfulId: row.onederfulId,
            Error_message: row.error_message,
            Error_reason: row.error_reason,
        }));

        // Create a new workbook and worksheet
        const workbook = new ExcelJS.Workbook();
        const worksheet = workbook.addWorksheet('Selected Data');

        // Add header row with styling
        const headerRow = worksheet.addRow(['OnederfulId', 'Error_message', 'Error_reason']);
        headerRow.eachCell((cell) => {
            cell.fill = {
                type: 'pattern',
                pattern: 'solid',
                fgColor: { argb: 'FFFF00' }, // Yellow background
            };
            cell.border = {
                top: { style: 'thin' },
                left: { style: 'thin' },
                bottom: { style: 'thin' },
                right: { style: 'thin' },
            };
        });

        // Set column widths (adjust these values based on your content)
        worksheet.getColumn(1).width = 40; // OnederfulId
        worksheet.getColumn(2).width = 50; // Error_message
        worksheet.getColumn(3).width = 50; // Error_reason

        // Add data rows
        filteredData.forEach((rows: any) => {
            worksheet.addRow([rows.OnederfulId, rows.Error_message, rows.Error_reason]);
        });

        // Export to Excel file
        const buffer = await workbook.xlsx.writeBuffer();
        const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
        const link = document.createElement('a');
        link.href = URL.createObjectURL(blob);
        link.download = 'Exported_Patient_Data.xlsx';
        link.click();

        // Clear selected rows and reset loading state
        selectedRowsPerPage[currentPage] = [];
        handleChangecheck({ selectedRows: [] });
        setLoading(false);
    };

    const [loading, setLoading] = useState(false);
    const customStyles = {
        rows: {
            style: {
                '&:hover': {
                    backgroundColor: '#f0f0f0',

                },
            },
        },
        headCells: {
            style: {
                backgroundColor: '#355876',  // Change background color of headers
                color: 'white',  // Change text color of headers
                fontSize: '16px',  // Adjust font size of headers
            },
        },
    };

    const onSubmit = async (data: IDefaultValues) => {
        if (!selectedClient) {

            Swal.fire({
                icon: 'warning',
                title: 'Insurance Verification Platform',
                text: 'Please Select Client!',
            });
            return;

        }
        if (!selectedtemplate) {

            Swal.fire({
                icon: 'warning',
                title: 'Insurance Verification Platform',
                text: 'Please Select Template!',
            });
            return;

        }
        if (!SelectedAddressname) {

            Swal.fire({
                icon: 'warning',
                title: 'Insurance Verification Platform',
                text: 'Please Select Address!',
            });
            return;

        }

        setLoading(true);
        const dateofbirth = new Date(data.DoB);
        const day = dateofbirth.getDate().toString().padStart(2, "0");
        const month = (dateofbirth.getMonth() + 1).toString().padStart(2, "0");
        const year = dateofbirth.getFullYear();
        const FullDate = month + "/" + day + "/" + year;

        const vendorid = (localStorage.UserType === 'Vendor') ? localStorage.VendorId : "";
        const userid = localStorage.id
        const Username = localStorage.UserName;

        const payload = {
            ClientId: selectedClientId,
            ClientName: selectedClient,
            template: selectedtemplate,
            templateId: selectedtemplateId,
            FirstName: data.firstName,
            MiddleName: data.MiddleName,
            lastName: data.lastName,
            DoB: FullDate,
            Username: Username,
            ProviderNPI: data.ProviderNPI,
            vendor_id: vendorid,
            Uploaded_userid: userid,
            // PayorID: data.PayorID,
            Memberid: data.Memberid,
            Insurancename: SelectedInsurancename,
            // InsuranceID: SelectedInsuranceId,
            Address: SelectedAddressname,
            AddressId: SelectedAddressId,
            Subscriber: data.Subscriber,
            Fee_Schedule: data.FeeSchedule,
            screenshot_status: Screenshot_status,

        }

        const auditLogData = [
            {
                field: "Patient Name",
                value: data.firstName + " " + data.lastName,
            },
            {
                field: "Client Name",
                value: selectedClient,
            },
            {
                field: "Template Name",
                value: selectedtemplate,
            },
            {
                field: "Patient DOB",
                value: FullDate,
            },
            {
                field: "Memberid",
                value: data.Memberid,
            },
            // {
            //     field: "Payer ID",
            //     value: data.PayorID,
            // },
            {
                field: "Provider NPI",
                value: data.ProviderNPI,
            },
            {
                field: "Insurance",
                value: SelectedInsurancename,
            },
            {
                field: "Address",
                value: SelectedAddressname,
            },
            {
                field: "Subscriber",
                value: data.Subscriber,
            },
            {
                field: "Screenshot Status",
                value: Screenshot_status
            }


        ]

        const auditData = { auditLogData, Username }


        const EncParams = AesEncrypt(JSON.stringify({ payload }));

        //  api/bulkupload/addPatientdetails

        const response = await fetch(webservice + 'api/bulkupload/addPatientdetails', {
            method: 'POST',
            body: JSON.stringify({ EncParams }),
            headers: {
                'Content-type': 'application/json; charset=UTF-8',
            }
        }).then(response => response.json());

        if (response === "success") {
            fetchData();
            const auditLogResponse = await axios.post(webservice + 'api/BulkUpload/AddauditLogdata', auditData, {

                headers: { 'Content-type': 'application/json; charset=UTF-8' }
            });
            Swal.fire({
                title: 'Insurance Verification Platform',
                html: '<span style="color: green;">Patient Added successfully!</span>',
                icon: 'success',
                customClass: {},
                showClass: {
                    popup: 'animate__animated animate__flipInX',
                },
            })
            setLoading(false);
            togglepatient();
            reset();
           
            setSelectedClient('');
            setSelectedClientId('');
            setselectedtemplate('');
            setselectedtemplateId('');
            handleReloadTable();
            setSelectedAddress('');
            setSelectedAddressId('');
            setAddress([]);
            
            // const api = await axios.get(webservice + 'api/elligibilityapi');
            // console.log(api);

            // if (api.data === 'Done') {
            //     const xmlcreation = await axios.get(webservice + 'api/elligibilityapi/Mapping');
            //     console.log('xmlcreation', xmlcreation);

            //     console.log('XML creation triggered:', xmlcreation.data);
            // } else {
            //     // console.log('API response is not "done". No XML creation triggered.');
            // }
        }
    };


    const startIndex = (currentPage - 1) * rowsPerPage + 1;
    const endIndex = Math.min(currentPage * rowsPerPage, store.total);
    const CustomLoadingComponent = () => {
        return (
            <div className="">
                {store.no_Data === 'There is no Record Found' ? (
                    <h4>
                        <br />
                        <p>There are no records to display</p>
                    </h4>
                ) : (
                    <div className="custom-loader">
                        <label className="custom-label">Please wait...</label>
                        <div className="custom-loading"></div>
                    </div>
                )}
            </div>
        );
    };

    return (
        <Fragment>
            <Card>
                <CardBody>
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <>
                            <div className="d-flex p-1 border" style={{ backgroundColor: "white" }}>
                                <CardBody>
                                    <Row>
                                        {localStorage.UserType === 'MOS' && (
                                            <Col xl={3}>
                                                <Label className="form-label" style={{ fontSize: "110%" }}>
                                                    Select Client <span style={{ color: "red" }}> * </span>
                                                </Label>
                                                <Select
                                                    isClearable
                                                    options={clientOptions}
                                                    classNamePrefix="select"
                                                    placeholder="Client Names"

                                                    className={classnames("form-control", {
                                                        "is-invalid": !!errors.ClientName,
                                                    })}
                                                    styles={{
                                                        control: (styles) => ({
                                                            ...styles,
                                                            width: "100%",
                                                            borderColor: "black",
                                                        }),
                                                        placeholder: (styles) => ({
                                                            ...styles,
                                                            textAlign: "center",
                                                        }),
                                                    }}
                                                    value={selectedClient ? { value: selectedClientId, label: selectedClient } : ""}
                                                    onChange={(selectedOption) => {
                                                        handleSelectClientChange(selectedOption as SingleValue<IClientOption>);
                                                    }}
                                                />
                                                {errors.ClientName && (
                                                    <span style={{ color: "red", fontSize: "12px" }}>
                                                        {errors.ClientName.message} {/* Display the error message */}
                                                    </span>
                                                )}
                                            </Col>
                                        )}
                                        {localStorage.UserType === 'Vendor' && (
                                            <Col xl={3}>
                                                <Label className="form-label" style={{ fontSize: "110%" }}>
                                                    Select Client <span style={{ color: "red" }}> * </span>
                                                </Label>
                                                <Select
                                                    isClearable
                                                    options={vendorbasedclientOptions}
                                                    classNamePrefix="select"
                                                    placeholder="Type Here"
                                                    className={classnames("form-control", {
                                                        "is-invalid": !!errors.ClientName,
                                                    })}
                                                    styles={{
                                                        control: (styles) => ({
                                                            ...styles,
                                                            width: "100%",
                                                            borderColor: "black",
                                                        }),
                                                        placeholder: (styles) => ({
                                                            ...styles,
                                                            textAlign: "center",
                                                        }),
                                                    }}
                                                    value={selectedClient ? { value: selectedClientId, label: selectedClient } : ""}
                                                    onChange={(selectedOption) => {
                                                        handleSelectClientChange(selectedOption as SingleValue<IClientOption>);
                                                    }}
                                                />
                                                {errors.ClientName && (
                                                    <span style={{ color: "red", fontSize: "12px" }}>
                                                        {errors.ClientName.message} {/* Display the error message */}
                                                    </span>
                                                )}
                                            </Col>
                                        )}
                                        <Col xl={3}>
                                            <Label className="form-label" style={{ fontSize: "110%" }}>
                                                Select Address <span style={{ color: "red" }}> * </span>
                                            </Label>
                                            <Select
                                                isClearable
                                                options={Addresseoption}
                                                // value={clientOptions.find((e)=>e.label===selectedClient)}
                                                classNamePrefix="select"
                                                placeholder="Type Here"
                                                //   theme={selectThemeColors}  
                                                className={classnames("form-control", {
                                                    "is-invalid": errors.Address && true,
                                                })}
                                                // {...field}
                                                styles={{
                                                    control: (styles) => ({
                                                        ...styles,
                                                        width: "100%",
                                                        borderColor: "black",
                                                    }),
                                                    placeholder: (styles) => ({
                                                        ...styles,
                                                        textAlign: "center",
                                                    }),
                                                }}
                                                value={SelectedAddressname ? { value: SelectedAddressId, label: SelectedAddressname } : ""}
                                                onChange={(selectedOption) => {
                                                    // field.onChange(selectedOption);
                                                    handleAddressChange(selectedOption as SingleValue<IClientOption>);
                                                }}
                                            />
                                            {errors.Address && (
                                                <span style={{ color: "red", fontSize: "12px" }}>
                                                    {errors.Address.message}
                                                </span>
                                            )}
                                        </Col>
                                        <Col xl={3}>
                                            <Label className="form-label" style={{ fontSize: "110%" }}>
                                                Select Template <span style={{ color: "red" }}> * </span>
                                            </Label>
                                            <Select
                                                isClearable
                                                options={templateOptions}
                                                // value={clientOptions.find((e)=>e.label===selectedClient)}
                                                classNamePrefix="select"
                                                placeholder="Type Here"
                                                //   theme={selectThemeColors}  
                                                className={classnames("form-control", {
                                                    "is-invalid": errors.ClientName && true,
                                                })}
                                                // {...field}
                                                styles={{
                                                    control: (styles) => ({
                                                        ...styles,
                                                        width: "100%",
                                                        borderColor: "black",
                                                    }),
                                                    placeholder: (styles) => ({
                                                        ...styles,
                                                        textAlign: "center",
                                                    }),
                                                }}
                                                value={selectedtemplate ? { value: selectedtemplateId, label: selectedtemplate } : ""}
                                                onChange={(selectedOption) => {
                                                    // field.onChange(selectedOption);
                                                    handleSelecttemplateChange(selectedOption as SingleValue<IClientOption>);
                                                }}

                                            />
                                            {errors.ClientName && (
                                                <span style={{ color: "red", fontSize: "12px" }}>
                                                    {errors.ClientName.message}
                                                </span>
                                            )}
                                        </Col>
                                        <Row style={{ marginTop: "2%" }}>
                                            <Col xl={12}>
                                                <h4>
                                                    <b>Patient Details:</b>
                                                </h4>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col xl={2}>
                                                <Label className="form-label" htmlFor="firstName">
                                                    First Name <span style={{ color: "red" }}>* </span>
                                                </Label>
                                                <Controller
                                                    control={control}
                                                    name="firstName"
                                                    render={({ field }) => (
                                                        <Input
                                                            type="text"
                                                            {...field}
                                                            id="firstName"
                                                            placeholder=""
                                                            invalid={!!errors.firstName}
                                                            onChange={(e) => {
                                                                let inputValue = e.target.value.replace(/[^A-Za-z]/gi, "");
                                                                if (inputValue.length > 0) {
                                                                    inputValue = inputValue.charAt(0).toUpperCase() + inputValue.slice(1);
                                                                }
                                                                field.onChange(inputValue);
                                                            }}
                                                        />
                                                    )}
                                                    rules={{ required: "First Name is required" }}
                                                />
                                                {errors.firstName && (
                                                    <FormFeedback>
                                                        {errors.firstName.message}
                                                    </FormFeedback>
                                                )}
                                            </Col>

                                            <Col xl={2}>
                                                <Label className="form-label" htmlFor="MiddleName">
                                                    Middle Name
                                                </Label>
                                                <Controller
                                                    control={control}
                                                    name="MiddleName"
                                                    render={({ field }) => (
                                                        <Input
                                                            {...field}
                                                            id="MiddleName"
                                                            placeholder=""
                                                            onChange={(e) => {
                                                                let inputValue = e.target.value.replace(/[^A-Za-z]/gi, "");
                                                                if (inputValue.length > 0) {
                                                                    inputValue = inputValue.charAt(0).toUpperCase() + inputValue.slice(1);
                                                                }
                                                                field.onChange(inputValue);
                                                            }}
                                                        />
                                                    )}
                                                />
                                            </Col>

                                            <Col xl={2}>
                                                <Label className="form-label" htmlFor="lastName">
                                                    Last Name <span style={{ color: "red" }}>* </span>
                                                </Label>
                                                <Controller
                                                    name="lastName"
                                                    control={control}
                                                    render={({ field }) => (
                                                        <Input
                                                            {...field}
                                                            id="lastName"
                                                            placeholder=""
                                                            invalid={!!errors.lastName}
                                                            onChange={(e) => {
                                                                let inputValue = e.target.value.replace(/[^A-Za-z]/gi, "");
                                                                if (inputValue.length > 0) {
                                                                    inputValue = inputValue.charAt(0).toUpperCase() + inputValue.slice(1);
                                                                }
                                                                field.onChange(inputValue);
                                                            }}
                                                        />
                                                    )}
                                                    rules={{ required: "Last Name is required" }}
                                                />
                                                {errors.lastName && (
                                                    <FormFeedback>
                                                        {errors.lastName.message}
                                                    </FormFeedback>
                                                )}
                                            </Col>
                                            <Col xl={3}>
                                                <Label className="form-label" htmlFor="Memberid">
                                                    Member ID <span style={{ color: "red" }}>* </span>
                                                </Label>
                                                <Controller
                                                    name="Memberid"
                                                    control={control}
                                                    defaultValue=""
                                                    render={({ field }) => (
                                                        <Input
                                                            // type="text"
                                                            {...field}
                                                            id="Memberid"
                                                            placeholder=""
                                                            invalid={!!errors.Memberid}
                                                            maxLength={15}

                                                        />
                                                    )}
                                                    rules={{ required: "Member ID is required" }}
                                                />
                                                {errors.Memberid && (
                                                    <FormFeedback>
                                                        {errors.Memberid.message}
                                                    </FormFeedback>
                                                )}
                                            </Col>
                                            <Col xl={3}>
                                                <Label className="form-label">Patient Type<span style={{ color: 'red' }}> * </span></Label>
                                                <Controller
                                                    name="Subscriber"
                                                    control={control}
                                                    rules={{ required: "Subscriber is required" }}
                                                    render={({ field }) => (
                                                        <Select<IOption>
                                                            {...field}
                                                            value={SubscriberOptions.find(option => option.value === field.value) || null}
                                                            options={SubscriberOptions}
                                                            getOptionLabel={(option) => option.label}
                                                            getOptionValue={(option) => option.value}
                                                            className={classnames("form-control", {
                                                                "is-invalid": !!errors.Subscriber,
                                                            })}
                                                            classNamePrefix="select"
                                                            onChange={(selectedOption: SingleValue<IOption>) => field.onChange(selectedOption?.value)}
                                                        />
                                                    )}
                                                />
                                                {errors.Subscriber && (
                                                    <FormFeedback>
                                                        {errors.Subscriber.message}
                                                    </FormFeedback>
                                                )}
                                            </Col>
                                        </Row>
                                        <Row style={{ marginTop: "2%" }}>
                                            {/* <Col xl={3}>
                                                <Label className="form-label" htmlFor="PayorID">
                                                    Payer ID <span style={{ color: "red" }}>* </span>
                                                </Label>
                                                <Controller
                                                    name="PayorID"
                                                    control={control}
                                                    defaultValue=""
                                                    render={({ field }) => (
                                                        <Input
                                                            // type=""
                                                            {...field}
                                                            id="PayorID"
                                                            placeholder=""
                                                            invalid={!!errors.PayorID}
                                                            maxLength={10}

                                                        />
                                                    )}
                                                    rules={{ required: "Payor ID is required" }}
                                                />
                                                {errors.PayorID && (
                                                    <FormFeedback>
                                                        {errors.PayorID.message}
                                                    </FormFeedback>
                                                )}
                                            </Col> */}
                                            <Col xl={3}>
                                                <Label className="form-label" htmlFor="DoB">
                                                    Date of Birth <span style={{ color: "red" }}>*</span>
                                                </Label>
                                                <Controller
                                                    control={control}
                                                    name="DoB"
                                                    rules={{
                                                        validate: (value) => {
                                                            const parsedDate = new Date(value);
                                                            return !isNaN(parsedDate.getTime()) || "";
                                                        },
                                                    }}
                                                    render={({ field }) => {
                                                        const handleDateChange = (date: Date | null) => {
                                                            if (date && date > new Date()) {
                                                                field.onChange("");
                                                            } else {
                                                                field.onChange(date ? date.toLocaleDateString("en-US") : "");
                                                            }
                                                        };

                                                        return (
                                                            <InputGroup>
                                                                <DatePicker
                                                                    selected={field.value ? new Date(field.value) : null}
                                                                    onChange={handleDateChange}
                                                                    dateFormat="MM/dd/yyyy"
                                                                    placeholderText="MM/DD/YYYY"
                                                                    className={classnames("form-control", {
                                                                        "is-invalid": !!errors.DoB,
                                                                    })}
                                                                    showPopperArrow={false}
                                                                />
                                                                <InputGroupText
                                                                    onClick={() => {
                                                                        const datePickerInput = document.querySelector(".react-datepicker__input-container input") as HTMLInputElement;
                                                                        if (datePickerInput) {
                                                                            datePickerInput.focus();
                                                                        }
                                                                    }}
                                                                >
                                                                    <FaCalendarAlt />
                                                                </InputGroupText>

                                                            </InputGroup>
                                                        );
                                                    }}
                                                />
                                                {errors.DoB && (
                                                    <p className="error-message" style={{ color: "#db3939", width: "100%", fontSize: "1.1rem", marginTop: "0.25rem" }}>
                                                        {errors.DoB.message}
                                                    </p>
                                                )}
                                            </Col>

                                            <Col xl={3}>
                                                <Label className="form-label" htmlFor="ProviderNPI">
                                                    Provider NPI ID
                                                </Label>
                                                <Controller
                                                    name="ProviderNPI"
                                                    control={control}
                                                    defaultValue=""
                                                    render={({ field }) => (
                                                        <Input
                                                            // type="number"
                                                            {...field}
                                                            id="ProviderNPI"
                                                            placeholder=""
                                                            invalid={!!errors.ProviderNPI}
                                                            maxLength={10}

                                                        />
                                                    )}
                                                // rules={{ required: "Provider NPI ID is required" }}
                                                />
                                                {errors.ProviderNPI && (
                                                    <FormFeedback>
                                                        {errors.ProviderNPI.message}
                                                    </FormFeedback>
                                                )}
                                            </Col>
                                            <Col xl={3}>
                                                <Label className="form-label">
                                                    Select Insurance<span style={{ color: 'red' }}> * </span>
                                                </Label>
                                                <Controller
                                                    name="Insurancename"
                                                    control={control}
                                                    render={({ field }) => (
                                                        <Select
                                                            isClearable
                                                            {...field}
                                                            options={insuranceoption}
                                                            classNamePrefix="select"
                                                            className={classnames("form-control", {
                                                                "is-invalid": !!errors.Insurancename,
                                                            })}
                                                            onChange={(selectedOption) => {
                                                                field.onChange(selectedOption);
                                                                handleinsuranceChange(selectedOption as SingleValue<Iinsuranceoption>);
                                                            }}
                                                        />
                                                    )}
                                                    rules={{ required: "Insurance is required" }}

                                                />
                                                {errors.Insurancename && (
                                                    <FormFeedback className="text-danger">
                                                        {errors.Insurancename.message}
                                                    </FormFeedback>
                                                )}
                                            </Col>
                                            <Col xl={3}>
                                                <Label className="form-label" htmlFor="FeeSchedule">
                                                    Fee Schedule
                                                </Label>
                                                <Controller
                                                    control={control}
                                                    name="FeeSchedule"
                                                    render={({ field }) => (
                                                        <Select<FeeScheduleOption>
                                                            {...field}
                                                            value={FeeScheduleOption.find(option => option.value === field.value) || null}
                                                            options={FeeScheduleOption}
                                                            getOptionLabel={(option) => option.label}
                                                            getOptionValue={(option) => option.value}
                                                            className={classnames("form-control", {
                                                                "is-invalid": !!errors.Subscriber,
                                                            })}
                                                            classNamePrefix="select"
                                                            onChange={(selectedOption: SingleValue<FeeScheduleOption>) => field.onChange(selectedOption?.value)}
                                                        />
                                                    )}
                                                />
                                            </Col>
                                            <Col md={5} xs={12} >
                                                <div className="d-flex" style={{ marginTop: "5%" }}  >
                                                    <Label
                                                        className="form-label me-2"
                                                        for="Screenshot_status"
                                                        style={{ fontSize: "120%", color: "#000" }}
                                                    >
                                                        Screenshot Status:
                                                    </Label>
                                                    <div className="form-check form-check-inline" style={{ display: "flex", alignItems: "center", justifyContent: "center", }}>
                                                        <input
                                                            type="radio"
                                                            name="Screenshot_status"
                                                            id="Screenshot_statusYes"
                                                            value="Yes"
                                                            onChange={handleScreenshot_statusChange}
                                                            className="radio-button"
                                                            style={{ width: "15px", height: "15px", margin: "0 0" }}
                                                        />
                                                        &nbsp;&nbsp;
                                                        <div>
                                                            <Label className="form-check-label" htmlFor="Screenshot_statusYes" style={{ color: "#28a745", marginBottom: "0px" }}>
                                                                YES
                                                            </Label>
                                                        </div>
                                                    </div>
                                                    <div className="form-check form-check-inline" style={{ display: "flex", alignItems: "center", justifyContent: "center", }}>
                                                        <input
                                                            type="radio"
                                                            name="Screenshot_status"
                                                            id="Screenshot_statusNo"
                                                            value="No"
                                                            onChange={handleScreenshot_statusChange}
                                                            style={{ width: "15px", height: "15px", margin: "0 0" }}
                                                            defaultChecked
                                                            className="radio-button" // Apply class for custom styling
                                                        />
                                                        &nbsp;&nbsp;
                                                        <div>
                                                            <Label className="form-check-label" htmlFor="Screenshot_statusNo" style={{ color: "#dc3545", marginBottom: "0px" }}>
                                                                NO
                                                            </Label>
                                                        </div>


                                                    </div>
                                                </div>
                                            </Col>

                                            <Row style={{ marginTop: "3%" }}>
                                                <Col xl={2}>
                                                    <Button
                                                        type="submit"
                                                        color="success"
                                                        className="btn-icon d-flex align-items-center justify-content-center"
                                                    >
                                                        Save Patient
                                                    </Button>
                                                </Col>
                                                <Col xl={2}>
                                                    <Button
                                                        className="btn-icon d-flex align-items-center justify-content-center"
                                                        color="danger"
                                                        onClick={() => {
                                                            setSelectedClient("");
                                                            setSelectedClientId("");
                                                            reset();
                                                        }}
                                                    >
                                                        Clear
                                                    </Button>
                                                </Col>
                                            </Row>
                                        </Row>
                                    </Row>
                                </CardBody>
                            </div>
                        </>
                    </form>
                    <CardBody>
                        <div className="table-responsive">
                            <div className="dataTables_wrapper">
                                <DataTable
                                    persistTableHead
                                    subHeader={true}
                                    responsive={true}
                                    onSort={handleSort}
                                    data={dataToRender()}
                                    columns={Columns}
                                    paginationServer
                                    customStyles={customStyles}
                                    paginationPerPage={rowsPerPage}
                                    paginationDefaultPage={currentPage}
                                    selectableRows
                                    selectableRowsHighlight
                                    selectableRowsComponent={BootstrapCheckbox as unknown as React.ReactNode}
                                    clearSelectedRows={loading}
                                    onSelectedRowsChange={handleChangecheck}
                                    paginationRowsPerPageOptions={[10, 20, 30, 50, 100]}
                                    noDataComponent={<CustomLoadingComponent />}
                                    paginationComponent={CustomPagination}
                                    subHeaderComponent={
                                        <CustomHeader
                                            value={value}
                                            rowsPerPage={rowsPerPage}
                                            handleFilter={handleFilter}
                                            handlePerPage={handlePerPage}
                                        />
                                    }
                                />
                                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                    <div style={{ paddingLeft: '2%' }}>
                                        {store.total && (
                                            <span><strong>{`${startIndex} to ${endIndex} of ${store.total} entries`}</strong></span>
                                        )}
                                    </div>
                                    <div>
                                        <CustomPagination />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </CardBody>
                </CardBody>
            </Card>
            {loading && (
                <div >
                    <div className="loading-overlay">
                        <div id="page">
                            <div id="container">
                                <div id="ring"></div>
                                <div id="ring"></div>
                                <div id="ring"></div>
                                <div id="ring"></div>
                                <div id="h3" style={{ color: "black" }}>Loading...</div>
                            </div>
                        </div>
                    </div>
                </div>
            )}
            <div>
                {eventId1 > 0 && menuActions.includes('delete') && (
                    <div className="customizer-links open">
                        <Nav className=" flex-column nav-pills">
                            <a className="nav-link" onClick={() => DeleteRow(eventId)}>
                                <span>Delete</span>
                                <Trash2 className="trash-icon" size={20} />
                            </a>
                        </Nav>
                    </div>
                )}
                {eventId1 > 0 && (
                    <div className="customizer1-links open">
                        <Nav className="flex-column nav-pills">
                            <a className="nav-link" onClick={() => Exportrow(eventId)}>
                                <span>Export</span>
                                <Download className="download-icon" size={20} />
                            </a>
                        </Nav>
                    </div>
                )}
            </div>

            <Modal isOpen={isModalOpen} toggle={() => setIsModalOpen(false)} size="xl" centered>
                <ModalBody style={{ textAlign: "center", padding: "20px" }}>
                    {viewingImage ? (
                        // Full-screen image view
                        <>
                            <img
                                src={`data:image/jpeg;base64,${patientData.image_base64}`}
                                alt="Full Screen View"
                                style={{
                                    width: "100%",
                                    height: "90vh",
                                    objectFit: "contain",
                                    borderRadius: "8px",
                                }}
                            />
                            <button
                                onClick={() => setViewingImage(false)}
                                style={{
                                    position: "absolute",
                                    top: "10px",
                                    right: "10px",
                                    background: "red",
                                    color: "white",
                                    border: "none",
                                    padding: "8px 12px",
                                    borderRadius: "4px",
                                    cursor: "pointer",
                                    fontSize: "16px",
                                }}
                            >
                                ✕ Close
                            </button>
                        </>
                    ) : loading ? (
                        <p style={{ color: "black" }}>Loading data...</p>
                    ) : patientData ? (
                        // Main error details view
                        <div className="email-wrapper">
                            <p>{patientData.Payer_name}</p>
                            <h3>Error: {patientData.error_message}</h3>
                            <p>
                                Location:{" "}
                                <a href={patientData.error_page_url} target="_blank" rel="noopener noreferrer">
                                    {patientData.error_page_url}
                                </a>
                            </p>

                            {patientData.image_base64 ? (
                                <div style={{ marginTop: "15px" }}>
                                    <img
                                        src={`data:image/jpeg;base64,${patientData.image_base64}`}
                                        alt="Error Screenshot"
                                        style={{
                                            width: "100%",
                                            maxWidth: "500px",
                                            borderRadius: "8px",
                                            cursor: "pointer",
                                        }}
                                        onClick={() => setViewingImage(true)}
                                    />
                                </div>
                            ) : (
                                <p>No image available</p>
                            )}
                        </div>
                    ) : (
                        <p>Please select a ticket to view details.</p>
                    )}
                </ModalBody>
            </Modal>
            <Modal isOpen={isOtpModalOpen} toggle={() => setIsOtpModalOpen(false)} size="sl" centered>
                <ModalBody style={{ textAlign: "center", padding: "20px" }}>
                    <h4>Enter OTP</h4>
                    <Input
                        type="text"
                        value={enteredOtp}
                        onChange={(e) => setEnteredOtp(e.target.value)}
                        placeholder="Enter OTP"
                    />
                    <Button color="primary" onClick={handleSubmitOtp} style={{ marginTop: "10px" }}>
                        Submit
                    </Button>
                </ModalBody>
            </Modal>
            <Modal isOpen={editOpen} toggle={() => setIsEditOpen(false)} size="xl" centered>
                <ModalBody style={{ textAlign: "center", padding: "20px" }}>
                    <Row style={{ marginTop: "2%" }}>
                        <Col xl={12}>
                            <h4><b>Edit Patient Details:</b></h4>
                        </Col>
                    </Row>
                    <br />
                    <Row>
                        <Col xl={4}>
                            <Label className="form-label" htmlFor="firstName">First Name</Label>
                            <Input
                                type="text"
                                name="firstName"
                                value={formValues.firstName}
                                onChange={handleInputChange}
                            />
                        </Col>
                        <Col xl={4}>
                            <Label className="form-label" htmlFor="middleName">Middle Name</Label>
                            <Input
                                type="text"
                                name="middleName"
                                value={formValues.middleName}
                                onChange={handleInputChange}
                            />
                        </Col>
                        <Col xl={4}>
                            <Label className="form-label" htmlFor="lastName">Last Name</Label>
                            <Input
                                type="text"
                                name="lastName"
                                value={formValues.lastName}
                                onChange={handleInputChange}
                            />
                        </Col>
                    </Row>
                    <Row style={{ marginTop: "2%" }}>
                        <Col xl={4}>
                            <Label className="form-label" htmlFor="memberId">Member ID</Label>
                            <Input
                                type="text"
                                name="memberId"
                                value={formValues.memberId}
                                onChange={handleInputChange}
                            />
                        </Col>
                        <Col xl={4}>
                            <Label className="form-label" htmlFor="dob">Date of Birth</Label>
                            <Input
                                type="text"
                                name="dob"
                                value={formValues.dob}
                                onChange={handleInputChange}
                            />
                        </Col>
                        <Col xl={4}>
                            <Label className="form-label" htmlFor="providerNpi">Provider NPI ID</Label>
                            <Input
                                type="text"
                                name="providerNpi"
                                value={formValues.providerNpi}
                                onChange={handleInputChange}
                            />
                        </Col>
                    </Row>
                    <br />
                    <Button color="primary" onClick={handleSubmitEditdata} style={{ marginTop: "10px" }}>
                        Update
                    </Button>
                </ModalBody>
            </Modal>
        </Fragment>
    );
}

export default Bulkupload;
