import React, { useState, useEffect, Fragment, InputHTMLAttributes, forwardRef, useCallback, useMemo } from 'react';
import { Button, Row, Col, Card, CardBody, InputGroup, Input, Nav, UncontrolledTooltip, Modal, ModalBody, Label } from 'reactstrap';
import { ChevronLeft, ChevronRight, Download, Edit, File, FileText, LogIn, Trash2 } from 'react-feather';
import DataTable from 'react-data-table-component';
import webservice from '../../../Service/webservice';
import { AesDecrypt, AesEncrypt } from '../../../Service/crypto';
import Breadcrumbs from '../../../CommonElements/Breadcrumbs/Breadcrumbs';
import 'flatpickr/dist/themes/material_blue.css'; // Import the CSS for the theme you want to use
import { Link, useNavigate, useParams } from 'react-router-dom';
import { saveAs } from 'file-saver'
import { useAppDispatch } from '../../../ReaduxToolkit/Hooks';
import { useSelector } from 'react-redux';
import ReactPaginate from 'react-paginate';
import { getPatientData, Get_permission } from './store';
import Swal from 'sweetalert2';
import axios from 'axios';
import moment from 'moment';
import websocket_webservice from '../../../Service/websocket_webservice'
import ExcelJS from 'exceljs';

interface DeleteRowData {
    Patientname: string;
    id: string;
    PatientDOB: string;
    Memberid: string;
    CompanyName: string;
    uploaded_date: string;
    RefId: string;
    Payor_ID: string;
    Provider_NPI: string;
    Payer_ID: string;
    Provider_Name: string;
    Provider_ID: string;
    template_mapping_time: string;
    xml_conversion_time: string;
    execution_time: string;
    Payer_Name: string;
    Data_Fetched: string;
    xml_conversion: string;
    error_message: string;
    error_reason: string;
    onederfulId: string;
    UserName: string;
    Type: string;
    Payer_name: string;
}

type PatientData = {
    First_Name: string;
    MiddleName: string;
    Last_Name: string;
    Memberid: string;
    Type: string;
    PatientDOB: string;
    Provider_ID: string;
    Payer_name: string;
};


const CustomHeader = ({ value, handleFilter, handlePerPage, rowsPerPage }: any) => {
    return (
        <div className='client-master-list-table-header w-100 py-2'>
            <Row>
                <Col lg='6' className='d-flex align-items-center px-0 px-lg-1'>
                    <div className='d-flex align-items-center me-4 '>
                        <label htmlFor='rows-per-page' className='me-2'>Rows per Page:</label>
                        <Input
                            type='select'
                            id='rows-per-page'
                            value={rowsPerPage}
                            onChange={handlePerPage}
                            className='form-control ms-50 larger-select '
                        >
                            <option value='10'>10</option>
                            <option value='25'>25</option>
                            <option value='50'>50</option>
                        </Input>
                    </div>
                    <Button tag={Link} to={'/BulkUpload'} color='secondary' style={{ marginLeft: "2%" }} >
                        Back
                    </Button>
                </Col>
                <Col
                    lg='6'
                    className='d-flex align-items-center justify-content-lg-end mt-lg-0 mt-1 px-0 px-lg-3'
                >
                    <InputGroup className="input-group-search mb-2 zoom-on-hover">
                        <Input
                            type="text"
                            placeholder="🔍 Search...."
                            value={value}
                            onChange={(e) => handleFilter(e.target.value)}
                            className="input-search"
                        />
                    </InputGroup>
                </Col>
            </Row>
        </div>
    );
};

const Bulkupload: React.FC = () => {
    const navigate = useNavigate();
    const { id } = useParams()
    const [loading, setLoading] = useState(false);
    const action = { fromUser: true };
    const dispatch = useAppDispatch()
    const store = useSelector((state: any) => state.Patients)
    const [value, setValue] = useState<string>('');
    const [sort, setSort] = useState<string>('asc');
    const [sortColumn, setSortColumn] = useState<any>('uploaded_date'); // Example initial sort column
    const [currentPage, setCurrentPage] = useState<number>(1);
    const [rowsPerPage, setRowsPerPage] = useState<number>(10);
    const [eventId, setEventId] = useState<DeleteRowData[]>([]);
    const [patientstatus, setpatientstatus] = useState<string>('');
    const [eventId1, setEventId1] = useState(0);
    const selectedRowsPerPage: { [key: number]: DeleteRowData[] } = useMemo(() => ({}), []);
    const Username = localStorage.UserName;
    const [menuActions, setMenuActions] = useState<string>('');
    const [editOpen, setIsEditOpen] = useState<boolean>(false);
    const [editData, setIsEditData] = useState<PatientData | null>(null);
    const [editId, setEditId] = useState<string>('');


    const [formValues, setFormValues] = useState({
        firstName: "",
        middleName: "",
        lastName: "",
        memberId: "",
        patientType: "",
        dob: "",
        providerNpi: "",
        insurance: "",
        feeSchedule: "",
    });

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setFormValues({
            ...formValues,
            [e.target.name]: e.target.value,
        });
    };

    useEffect(() => {
        if (localStorage.id !== null) {
            dispatch(Get_permission(localStorage.id)).then((response) => {
                const res1 = response.payload;
                const Action = res1[0].Permission.filter(
                    (item: any) => item.MenuKey === "PATIENTS"
                );
                setMenuActions(Action[0].MenuActions);
            });
        }
    }, [localStorage.id])

    const [localData, setLocalData] = useState<DeleteRowData[]>(store.data);
    
    

    useEffect(() => {
        const fetchData = async () => {
            if (id !== undefined) {
                try {
                    const actionResult = await dispatch(
                        getPatientData({
                            id,
                            q: value,
                            perPage: rowsPerPage,
                            page: currentPage,
                            sort,
                            sortColumn,
                        })
                    );

                    const payload = actionResult.payload as { data: DeleteRowData[] };
                    setLocalData(payload.data);
                } catch (error) {
                    console.error("Error fetching patient data:", error);
                }
            }
        };

        fetchData();

        const socket = new WebSocket(`${websocket_webservice}ws/BulkuploadPatient/`);
        // socket.onopen = () => console.log("WebSocket connected");
        socket.onmessage = (event) => {
            const change = JSON.parse(event.data);
            // console.log('change',change);
            
            setLocalData((prevData: DeleteRowData[]) =>
                prevData.map((item) =>
                    item.id === change.documentKey._id.$oid
                        ? { ...item, ...change.updateDescription.updatedFields }
                        : item
                )
            );
        };

        return () => {
            if (socket.readyState === 1) {
                socket.close();
            }
        };
    }, [dispatch, id, value, rowsPerPage, currentPage, sort, sortColumn]);



    const handleReloadTable = () => {
        dispatch(
            getPatientData({
                id,
                q: value,
                perPage: rowsPerPage,
                page: currentPage,
                sort,
                sortColumn
            })
        );
    };


    const handleFilter = (val: any) => {
        setValue(val)
        dispatch(
            getPatientData({
                id,
                q: val,
                perPage: rowsPerPage,
                page: currentPage,
                sort,
                sortColumn,
            })
        )
    }

    const handlePerPage = (e: any) => {
        setCurrentPage(1)
        dispatch(
            getPatientData({
                id,
                q: value,
                perPage: parseInt(e.target.value),
                page: currentPage,
                sort,
                sortColumn,
            })
        )
        setRowsPerPage(parseInt(e.target.value))
    }



    const handlePagination = (page: any) => {
        dispatch(
            getPatientData({
                id,
                q: value,
                perPage: rowsPerPage,
                page: page.selected + 1,
                sort,
                sortColumn,
            })
        )
        setCurrentPage(page.selected + 1)
    }


    const dataToRender = () => {
        const filters: any = {
            q: value

        }
        const isFiltered = Object.keys(filters).some(function (k) {
            return filters[k].length > 0
        })

        if (localData.length > 0) {
            return localData
        } else if (localData.length === 0 && isFiltered) {
            return []
        } else {
            return store.allData.slice(0, rowsPerPage)
        }
    }

    const handleSort = (column: any, sortDirection: any) => {
        setSort(sortDirection)
        setSortColumn(column.sortField)
        dispatch(
            getPatientData({
                id,
                q: value,
                perPage: rowsPerPage,
                page: currentPage,
                sort: sortDirection,
                sortColumn: column.sortField
            })
        )
    }

    const CustomPagination = () => {
        const count = Number(Math.ceil(store.total / rowsPerPage))

        return (
            <ReactPaginate
                previousLabel={<ChevronLeft size={16} />}
                nextLabel={<ChevronRight size={16} />}
                breakLabel="..."
                pageCount={count || 1}
                onPageChange={handlePagination}
                forcePage={currentPage !== 0 ? currentPage - 1 : 0}
                containerClassName="pagination-container"
                pageClassName="page-item"
                pageLinkClassName="page-link"
                previousClassName="page-item"
                previousLinkClassName="page-link"
                nextClassName="page-item"
                nextLinkClassName="page-link"
                breakClassName="page-item"
                breakLinkClassName="page-link"
                activeClassName="active"
            />
        )
    }


    const [isDownloading, setIsDownloading] = useState(null);

    const handleOpenWordDocument = async (id: any, patient: any) => {
        setIsDownloading(id);
        try {
            const EncParams = AesEncrypt(JSON.stringify({ id: id! })); // Add a non-null assertion here
            const response = await fetch(webservice + 'api/getPatientFile_word', {
                method: 'POST',
                body: JSON.stringify({ EncParams }),
                headers: {
                    'Content-type': 'application/json; charset=UTF-8',
                }
            });
            const responseData = await response.json();
            const OutData = AesDecrypt(responseData)

            const docxData = Uint8Array.from(atob(OutData.file), (c) => c.charCodeAt(0));
            const blob = new Blob([docxData], {
                type: "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
            });
            const filename = OutData.filename
            saveAs(blob, filename + '.docx')
            setIsDownloading(null);
            // Check if the file_data key exists
            if (!OutData.file) {
                throw new Error('No file data in response');
            }
        } catch (error) {
            console.error('Error downloading file: ', error);
        }
    };

    const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
    const [patientData, setPatientData] = useState<any>(null);
    const [viewingImage, setViewingImage] = useState<any>(false);

    const handleErrorClick = async (row: any) => {
        const patientId = row.id
        setLoading(true);
        try {
            const EncParams = AesEncrypt(patientId);
            const response = await fetch(webservice + "api/Email-fetchErrorDatas", {
                method: "POST",
                body: JSON.stringify({ EncParams }),
                headers: { "Content-Type": "application/json; charset=UTF-8" },
            }).then((res) => res.json());
            setLoading(false);
            setIsModalOpen(true);
            setPatientData(response[0]);
        } catch (error) {
            console.error("Error fetching patient data:", error);
        } finally {
            setLoading(false); // Hide loading after fetching data
        }

    };


    const handleregenrateDocument = async (rows: any[]) => {
        setLoading(true);
        try {
            const ids = rows.map(row => row.id);
            const EncParams = AesEncrypt(JSON.stringify({ ids }));
            const fetchResponse = await fetch(webservice + 'api/regenratepatients', {
                method: 'POST',
                body: JSON.stringify({ EncParams }),
                headers: {
                    'Content-type': 'application/json; charset=UTF-8',
                },
            });
            const data = await fetchResponse.json();
            const data1 = AesDecrypt(data);

            // await axios.get(webservice + 'api/elligibilityapi');
            // First API call to check the condition
            const api = await axios.get(webservice + 'api/elligibilityapi');
            if (api.data === 'done') {
                const xmlcreation = await axios.get(webservice + 'api/elligibilityapi/Mapping');
                // console.log('XML creation triggered:', xmlcreation.data);
            } else {
                // console.log('API response is not "done". No XML creation triggered.');
            }
            if (data1 === "S") {
                Swal.fire({
                    icon: "success",
                    title: "Insurance Verification Platform",
                    text: "Patients have been Regenrated.",
                    customClass: {
                        confirmButton: "btn btn-success",
                    },
                    allowOutsideClick: false
                });
                setLoading(false);
                navigate(`/patients`);
            }
        } catch (error) {
            console.error('Error downloading file: ', error);
        }
    };
    interface BootstrapCheckboxProps extends InputHTMLAttributes<HTMLInputElement> {
        id: string;
    }
    // Handle individual row checkbox change
    const BootstrapCheckbox = forwardRef<HTMLInputElement, BootstrapCheckboxProps>(
        ({ id, ...props }, ref) => (
            <div style={{ marginBottom: '10px', position: 'relative', left: '10px' }}>
                <input type='checkbox' id={id} ref={ref} {...props} />
            </div>
        )
    );

    const [showRegenerateButton, setShowRegenerateButton] = useState(false);
    const timezone = moment.tz.guess();
    const handleChangecheck = useCallback(
        ({ selectedRows }: any) => {
            const selectedEventIds = [];
            selectedRows.forEach((row: any) => {
                selectedEventIds.push(row.id);
                setpatientstatus(row.Data_Fetched)

                const uploadedDate = moment.utc(row.uploaded_date).tz(timezone);

                // Calculate end date by adding Dayscount to the uploaded date
                const endDate = uploadedDate.clone().add(row.Dayscount, 'days');

                // Get current date
                const currentDate = moment.utc(new Date()).tz(timezone);

                if (currentDate.isSameOrAfter(endDate)) {
                    setShowRegenerateButton(true);
                } else {
                    setShowRegenerateButton(false);
                }


            });

            setEventId(selectedRows)
            setEventId1(selectedEventIds.length)


            if (!action.fromUser)
                return;
            selectedRowsPerPage[currentPage] = selectedRows;
        },
        [currentPage, selectedRowsPerPage, action.fromUser]);

    const startViewTimer = () => {
        const startTime = new Date().getTime();
        localStorage.setItem('ViewStartTime', startTime.toString());
    };

    const handleEdit = async (row: any) => {
        if (!row || typeof row !== "object") return;
        setIsEditOpen(true)
        setIsEditData(row)
        setEditId(row.id)
    };

    useEffect(() => {
        if (editOpen && editData) {
            setFormValues({
                firstName: editData.First_Name || "",
                middleName: editData.MiddleName || "",
                lastName: editData.Last_Name || "",
                memberId: editData.Memberid || "",
                patientType: editData.Type || "",
                dob: editData.PatientDOB || "",
                providerNpi: editData.Provider_ID || "",
                insurance: editData.Payer_name || "",
                feeSchedule: "", // Set default value if needed
            });
        }
    }, [editOpen, editData]);


    const handleSubmitEditdata = async () => {
        setLoading(true);
        const data = JSON.stringify({ editId, formValues }); // Convert to string before encryption
        const auditLogData = [
            {
                field: 'Patient Name',
                before: `${editData?.First_Name} ${editData?.Last_Name}` || '',
                after: `${formValues.firstName} ${formValues.lastName}`,
            },
            {
                field: 'Date of Birth',
                before: editData?.PatientDOB || '',
                after: formValues.dob,
            },
            {
                field: 'Member ID',
                before: editData?.Memberid || '',
                after: formValues.memberId,
            },
            {
                field: 'Provider NPI',
                before: editData?.Provider_ID || '',
                after: formValues.providerNpi,
            },
        ];
        const Username = localStorage.getItem('UserName') || '';
        const auditData = { auditLogData, Username };
        try {
            const EncParams = AesEncrypt(data);
            const response = await fetch(webservice + "api/edit_patient_data", {
                method: "POST",
                body: JSON.stringify({ EncParams }),
                headers: { "Content-Type": "application/json; charset=UTF-8" },
            })
            const responseData = await response.json();
            if (responseData === "Patient Updated Successfully") {
                await axios.post(webservice + 'api/BulkUpload/EditauditLogdata', auditData, {
                    headers: { 'Content-type': 'application/json; charset=UTF-8' }
                });
                Swal.fire({
                    icon: "success",
                    title: "Insurance Verification Platform",
                    text: "Patient Updated Successfully",
                    customClass: {
                        confirmButton: "btn btn-success",
                    },
                    allowOutsideClick: false
                });
                setIsEditOpen(false);
                setLoading(false);
                handleReloadTable();
            }
        } catch (error) {
            console.error("Error fetching patient data:", error);
        }
    };


    const Columns = [
        { name: 'Uploaded by', selector: (row: { UserName: string }) => row.UserName ? row.UserName : 'NA', sortable: true, width: "160px" },
        { name: 'Patient Name', selector: (row: { Patientname: string }) => row.Patientname ? row.Patientname : 'NA', sortable: true, width: "160px" },
        { name: 'Patient DOB', selector: (row: { PatientDOB: string }) => row.PatientDOB ? row.PatientDOB : 'NA', sortable: true, width: "160px" },
        { name: 'Member ID', selector: (row: { Memberid: string }) => row.Memberid ? row.Memberid : 'NA', sortable: true, width: "160px" },
        { name: 'Client Name', selector: (row: { CompanyName: string }) => row.CompanyName ? row.CompanyName : 'NA', sortable: true, width: "160px" },
        { name: 'Payer ID', selector: (row: { Payer_ID: string }) => row.Payer_ID ? row.Payer_ID : 'NA', sortable: true, width: "160px" },
        { name: 'Insurance Name', selector: (row: { Payer_name: string }) => row.Payer_name ? row.Payer_name : 'NA', sortable: true, width: "300px" },
        { name: 'Provider ID', selector: (row: { Provider_ID: string }) => row.Provider_ID ? row.Provider_ID : 'NA', sortable: true, width: "160px" },
        { name: 'Type', selector: (row: { Type: string }) => row.Type ? row.Type : 'NA', sortable: true, width: "160px" },
        {
            name: (
                <>
                    Template <br /> Generation Time
                </>
            ),
            selector: (row: { template_mapping_time: string }) =>
                row.template_mapping_time ? `${row.template_mapping_time} sec` : "NA",
            sortable: true
        },
        {
            name: (
                <>
                    XML <br /> Conversion Time
                </>
            ),
            selector: (row: { xml_conversion_time: string }) =>
                row.xml_conversion_time ? `${row.xml_conversion_time} sec` : "NA",
            sortable: true
        },
        {
            name: (
                <>
                    Execution Time
                </>
            ),
            selector: (row: { execution_time: string }) => row.execution_time ? `${row.execution_time} min` : "NA",
            sortable: true
        },
        { name: 'BOT Status', selector: (row: { Data_Fetched: string }) => row.Data_Fetched === 'Completed' ? 'Received' : row.Data_Fetched || 'NA', sortable: true, width: "160px" },
        {
            name: "View Patient",
            cell: (row: DeleteRowData) => (
                <>
                    {row.Data_Fetched === "Completed" && menuActions.includes("edit") && (
                        <>
                            <FileText
                                size={17}
                                id="editIcon"
                                // color='#FFA500'
                                color={row.xml_conversion === "C" ? '#FFA500' : '#800080'}
                                onClick={() => {
                                    startViewTimer();
                                    navigate(`/patient-eligibility/${row.id}?view=/bulkupload/patient/${id}`);
                                }}
                                style={{ cursor: 'pointer', marginRight: '10px' }}
                            />
                            <UncontrolledTooltip placement="top" target="editIcon">
                                View Patient Details
                            </UncontrolledTooltip>
                        </>
                    )}
                    {row.xml_conversion === "C" && (
                        <div onClick={() => handleOpenWordDocument(row.id, row.Patientname)}>
                            {isDownloading === row.id ? (
                                <div className="spinner"></div>
                            ) : (
                                <Download size={20} style={{ cursor: 'pointer' }} />
                            )}
                        </div>
                    )}
                    {row.Data_Fetched === "Error" && (
                            <>
                                <FileText
                                    size={17}
                                    id={`error_message_${row.id}`}  // Unique ID for each row
                                    color="red"
                                    onClick={() => {
                                        handleErrorClick(row);
                                    }}
                                    style={{ cursor: 'pointer', marginRight: '10px' }}
                                />
                                <UncontrolledTooltip placement="top" target={`error_message_${row.id}`}>
                                    <p style={{ color: "#e61616" }}>{row.error_message}</p>
                                </UncontrolledTooltip>
                            </>
                        )}
                    &nbsp;&nbsp;&nbsp;
                    {row.Data_Fetched?.trim() === "Error" && row.error_message?.includes("Error in patient details") && (
                        <>
                            <Edit size={20} style={{ cursor: 'pointer' }} id={`EditIcon-${row.id}`} onClick={() => { handleEdit(row); }} />
                            <UncontrolledTooltip placement="top" target={`EditIcon-${row.id}`}>
                                Edit
                            </UncontrolledTooltip>
                        </>
                    )}

                </>
            )
        },
        // {
        //     name: 'Error Reason',
        //     selector: (row: { error_reason: string }) => row.error_reason || 'NA',
        //     sortable: true,
        //     cell: (row: { error_reason: string }) => (
        //         <span 
        //         style={{ color: row.error_reason ? 'red' : 'black' }}
        //         title={row.error_reason}
        //     >
        //         {(row.error_reason ? row.error_reason.split(" ").slice(0, 10).join(" ") : "NA")}
        //         {row.error_reason && row.error_reason.split(" ").length > 10 && "..."}
        //     </span>
        //     )
        //   }
    ]


    const customStyles = {
        rows: {
            style: {
                '&:hover': {
                    backgroundColor: '#f0f0f0',

                },
            },
        },
        headCells: {
            style: {
                backgroundColor: '#355876',
                color: 'white',
                fontSize: '16px',
            },
        },
    };





    const deletePatient = async (ids: any[]) => {
        const EncParams = AesEncrypt(JSON.stringify(ids));
        await fetch(webservice + 'api/bulkupload/deletePatientdetails', {
            method: 'POST',
            body: JSON.stringify({ EncParams }),
            headers: {
                'Content-type': 'application/json; charset=UTF-8',
            }
        });
    };

    const DeleteAuditLogdata = async (data: any[]) => {
        data.forEach(item => {
            item.Userid = localStorage.id;
            item.ParentUserId = localStorage.ParentUserId;
        });
        const EncParams = AesEncrypt(JSON.stringify(data));
        const response = await fetch(webservice + 'api/bulkupload/deleteauditlog', {
            method: 'POST',
            body: JSON.stringify({ EncParams }),
            headers: {
                'Content-type': 'application/json; charset=UTF-8',
            }
        }).then(response => response.json());
        return response;
    };

    const DeletePatient = async (rows: any[]) => {
        const ids = rows.map(row => row.id);
        const auditData = rows.map(row => ({
            Username,
            RefId: row.RefId,
            Name: row.Patientname
        }));

        const deleteRow = async () => {
            Swal.fire({
                title: "Insurance Verification Platform",
                text: "Are you sure do you want to delete?",
                icon: "warning",
                showCancelButton: true,
                confirmButtonText: "Yes",
                customClass: {
                    confirmButton: "btn btn-primary",
                    cancelButton: "btn btn-danger ms-1",
                },
                buttonsStyling: false,
                allowOutsideClick: false
            })
                .then(async function (result) {
                    if (result.isConfirmed) {
                        await deletePatient(ids);
                        const res = await DeleteAuditLogdata(auditData);

                        Swal.fire({
                            icon: "success",
                            title: "Insurance Verification Platform",
                            text: "Clients have been deleted.",
                            customClass: {
                                confirmButton: "btn btn-success",
                            },
                            allowOutsideClick: false
                        });
                        handleReloadTable();
                    }
                });
        };
        deleteRow();
    };


    const startIndex = (currentPage - 1) * rowsPerPage + 1;
    const endIndex = Math.min(currentPage * rowsPerPage, store.total);
    const CustomLoadingComponent = () => {
        return (
            <div className="">
                {store.no_Data === 'There is no Record Found' ? (
                    <h4>
                        <br />
                        <p>There are no records to display</p>
                    </h4>
                ) : (
                    <div className="custom-loader">
                        <label className="custom-label">Please wait...</label>
                        <div className="custom-loading"></div>
                    </div>
                )}
            </div>
        );
    };

    const Exportrow = async (rows: any) => {
        setLoading(true);
        // Extract only the necessary columns
        const filteredData = rows.map((row: any) => ({
            OnederfulId: row.onederfulId,
            Error_message: row.error_message,
            Error_reason: row.error_reason,
        }));

        // Create a new workbook and worksheet
        const workbook = new ExcelJS.Workbook();
        const worksheet = workbook.addWorksheet('Selected Data');

        // Add header row with styling
        const headerRow = worksheet.addRow(['OnederfulId', 'Error_message', 'Error_reason']);
        headerRow.eachCell((cell) => {
            cell.fill = {
                type: 'pattern',
                pattern: 'solid',
                fgColor: { argb: 'FFFF00' }, // Yellow background
            };
            cell.border = {
                top: { style: 'thin' },
                left: { style: 'thin' },
                bottom: { style: 'thin' },
                right: { style: 'thin' },
            };
        });

        // Set column widths (adjust these values based on your content)
        worksheet.getColumn(1).width = 40; // OnederfulId
        worksheet.getColumn(2).width = 50; // Error_message
        worksheet.getColumn(3).width = 50; // Error_reason

        // Add data rows
        filteredData.forEach((rows: any) => {
            worksheet.addRow([rows.OnederfulId, rows.Error_message, rows.Error_reason]);
        });

        // Export to Excel file
        const buffer = await workbook.xlsx.writeBuffer();
        const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
        const link = document.createElement('a');
        link.href = URL.createObjectURL(blob);
        link.download = 'Exported_Patient_Data.xlsx';
        link.click();

        // Clear selected rows and reset loading state
        selectedRowsPerPage[currentPage] = [];
        handleChangecheck({ selectedRows: [] });
        setLoading(false);
    };

    return (
        <Fragment>
            <Breadcrumbs mainTitle='View Patients' parent='Bulk Upload' title='View Patients' to={`/BulkUpload`} />
            <Card>
                <CardBody>
                    <div className="table-responsive">
                        <div className="dataTables_wrapper">
                            <DataTable
                                persistTableHead
                                subHeader={true}
                                responsive={true}
                                onSort={handleSort}
                                data={dataToRender()}
                                columns={Columns}
                                paginationServer
                                customStyles={customStyles}
                                paginationPerPage={rowsPerPage}
                                paginationDefaultPage={currentPage}
                                selectableRows
                                selectableRowsHighlight
                                selectableRowsComponent={BootstrapCheckbox as unknown as React.ReactNode}
                                onSelectedRowsChange={handleChangecheck}
                                paginationRowsPerPageOptions={[10, 20, 30, 50, 100]}
                                noDataComponent={<CustomLoadingComponent />}
                                paginationComponent={CustomPagination}
                                clearSelectedRows={loading}
                                subHeaderComponent={
                                    <CustomHeader
                                        value={value}
                                        rowsPerPage={rowsPerPage}
                                        handleFilter={handleFilter}
                                        handlePerPage={handlePerPage}
                                    />}
                            />
                            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                <div style={{ paddingLeft: '2%' }}>
                                    {store.total && (
                                        <span><strong>{`${startIndex} to ${endIndex} of ${store.total} entries`}</strong></span>
                                    )}
                                </div>
                                <div>
                                    <CustomPagination />
                                </div>
                            </div>
                        </div>
                    </div>
                </CardBody>
                {loading && (
                    <div >
                        <div className="loading-overlay">
                            <div id="page">
                                <div id="container">
                                    <div id="ring"></div>
                                    <div id="ring"></div>
                                    <div id="ring"></div>
                                    <div id="ring"></div>
                                    <div id="h3" style={{ color: "black" }}>loading...</div>
                                </div>
                            </div>
                        </div>
                    </div>
                )}

            </Card>
            <div>
                {eventId1 > 0 && menuActions.includes('delete') && (
                    <div className="customizer-links open">
                        <Nav className=" flex-column nav-pills">
                            <a className="nav-link" onClick={() => DeletePatient(eventId)}>
                                <span>Delete</span>
                                <Trash2 className="trash-icon" size={20} />
                            </a>
                        </Nav>
                    </div>
                )}
                {/* {eventId1 > 0 && (
    <div className="customizer1-links open">
        {patientstatus !== 'Error' && showRegenerateButton && (
                <Nav className="flex-column nav-pills">
                    <div>
                        <a className="nav-link" onClick={() => handleregenrateDocument(eventId)}>
                            <span>Regenerate</span>
                            <LogIn size={20} style={{ cursor: 'pointer' }} />
                        </a>
                    </div>
                </Nav>
            )}
    </div>
)} */}
                {eventId1 > 0 && (
                    <div className="customizer1-links open">
                        <Nav className="flex-column nav-pills">
                            <a className="nav-link" onClick={() => Exportrow(eventId)}>
                                <span>Export</span>
                                <Download className="download-icon" size={20} />
                            </a>
                        </Nav>
                    </div>
                )}
            </div>

            {/* <Modal isOpen={isModalOpen} toggle={() => setIsModalOpen(false)} size="xl" centered>
                <ModalBody style={{ textAlign: "center", padding: "20px" }}>
                    {viewingImage ? (
                        // Full-screen image view
                        <>
                            <img
                                src={`data:image/jpeg;base64,${patientData.image_base64}`}
                                alt="Full Screen View"
                                style={{
                                    width: "100%",
                                    height: "90vh",
                                    objectFit: "contain",
                                    borderRadius: "8px",
                                }}
                            />
                            <button
                                onClick={() => setViewingImage(false)}
                                style={{
                                    position: "absolute",
                                    top: "10px",
                                    right: "10px",
                                    background: "red",
                                    color: "white",
                                    border: "none",
                                    padding: "8px 12px",
                                    borderRadius: "4px",
                                    cursor: "pointer",
                                    fontSize: "16px",
                                }}
                            >
                                ✕ Close
                            </button>
                        </>
                    ) : loading ? (
                        <p style={{ color: "black" }}>Loading data...</p>
                    ) : patientData ? (
                        // Main error details view
                        <div className="email-wrapper">
                            <p>{patientData.Payer_name}</p>
                            <h3>Error: {patientData.error_message}</h3>
                            <p>
                                Location:{" "}
                                <a href={patientData.error_page_url} target="_blank" rel="noopener noreferrer">
                                    {patientData.error_page_url}
                                </a>
                            </p>

                            {patientData.image_base64 ? (
                                <div style={{ marginTop: "15px" }}>
                                    <img
                                        src={`data:image/jpeg;base64,${patientData.image_base64}`}
                                        alt="Error Screenshot"
                                        style={{
                                            width: "100%",
                                            maxWidth: "500px",
                                            borderRadius: "8px",
                                            cursor: "pointer",
                                        }}
                                        onClick={() => setViewingImage(true)}
                                    />
                                </div>
                            ) : (
                                <p>No image available</p>
                            )}
                        </div>
                    ) : (
                        <p>Please select a ticket to view details.</p>
                    )}
                </ModalBody>
            </Modal> */}
            <Modal isOpen={isModalOpen} toggle={() => setIsModalOpen(false)} size="xl" centered>
    <ModalBody style={{ textAlign: "center", padding: "20px", maxHeight: "90vh", overflowY: "auto" }}>
        {viewingImage ? (
            // Full-screen image view
            <>
                <img
                    src={`data:image/jpeg;base64,${patientData.image_base64}`}
                    alt="Full Screen View"
                    style={{
                        width: "100%",
                        height: "90vh",
                        objectFit: "contain",
                        borderRadius: "8px",
                    }}
                />
                <button
                    onClick={() => setViewingImage(false)}
                    style={{
                        position: "absolute",
                        top: "10px",
                        right: "10px",
                        background: "red",
                        color: "white",
                        border: "none",
                        padding: "8px 12px",
                        borderRadius: "4px",
                        cursor: "pointer",
                        fontSize: "16px",
                    }}
                >
                    ✕ Close
                </button>
            </>
        ) : loading ? (
            <p style={{ color: "black" }}>Loading data...</p>
        ) : patientData ? (
            // Main error details view
            <div className="email-wrapper" style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                <p>{patientData.Payer_name}</p>
                <h3>Error: {patientData.error_message}</h3>
                <p>
                    Location:{" "}
                    <a href={patientData.error_page_url} target="_blank" rel="noopener noreferrer">
                        {patientData.error_page_url}
                    </a>
                </p>

                {patientData.image_base64 ? (
                    <div style={{ marginTop: "15px" }}>
                        <img
                            src={`data:image/jpeg;base64,${patientData.image_base64}`}
                            alt="Error Screenshot"
                            style={{
                                width: "100%",
                                maxWidth: "500px",
                                borderRadius: "8px",
                                cursor: "pointer",
                            }}
                            onClick={() => setViewingImage(true)}
                        />
                    </div>
                ) : (
                    <p>No image available</p>
                )}
            </div>
        ) : (
            <p>Please select a ticket to view details.</p>
        )}
    </ModalBody>
</Modal>

            <Modal isOpen={editOpen} toggle={() => setIsEditOpen(false)} size="xl" centered>
                <ModalBody style={{ textAlign: "center", padding: "20px" }}>
                    <Row style={{ marginTop: "2%" }}>
                        <Col xl={12}>
                            <h4><b>Edit Patient Details:</b></h4>
                        </Col>
                    </Row>
                    <br />
                    <Row>
                        <Col xl={4}>
                            <Label className="form-label" htmlFor="firstName">First Name</Label>
                            <Input
                                type="text"
                                name="firstName"
                                value={formValues.firstName}
                                onChange={handleInputChange}
                            />
                        </Col>
                        <Col xl={4}>
                            <Label className="form-label" htmlFor="middleName">Middle Name</Label>
                            <Input
                                type="text"
                                name="middleName"
                                value={formValues.middleName}
                                onChange={handleInputChange}
                            />
                        </Col>
                        <Col xl={4}>
                            <Label className="form-label" htmlFor="lastName">Last Name</Label>
                            <Input
                                type="text"
                                name="lastName"
                                value={formValues.lastName}
                                onChange={handleInputChange}
                            />
                        </Col>
                    </Row>
                    <Row style={{ marginTop: "2%" }}>
                        <Col xl={4}>
                            <Label className="form-label" htmlFor="memberId">Member ID</Label>
                            <Input
                                type="text"
                                name="memberId"
                                value={formValues.memberId}
                                onChange={handleInputChange}
                            />
                        </Col>
                        <Col xl={4}>
                            <Label className="form-label" htmlFor="dob">Date of Birth</Label>
                            <Input
                                type="text"
                                name="dob"
                                value={formValues.dob}
                                onChange={handleInputChange}
                            />
                        </Col>
                        <Col xl={4}>
                            <Label className="form-label" htmlFor="providerNpi">Provider NPI ID</Label>
                            <Input
                                type="text"
                                name="providerNpi"
                                value={formValues.providerNpi}
                                onChange={handleInputChange}
                            />
                        </Col>
                    </Row>
                    <br />
                    <Button color="primary" onClick={handleSubmitEditdata} style={{ marginTop: "10px" }}>
                        Update
                    </Button>
                </ModalBody>
            </Modal>
        </Fragment>
    );
}

export default Bulkupload;
