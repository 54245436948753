import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import webservice from '../../../../Service/webservice';
import { AesDecrypt, AesEncrypt } from "../../../../Service/crypto";

export const fetchTicketDatas = createAsyncThunk('Email/fetchTicketDatas', async (data:any) => {


  const response = await fetch(webservice + 'api/Email-fetchTicketDatas', {
    method: 'POST',
    body: JSON.stringify({ data }),
    headers: {
      'Content-type': 'application/json; charset=UTF-8',
    },
  })
  .then((response1) => response1.json())
  
  const TempData = response
  return TempData;
})